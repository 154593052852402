import { PropsWithChildren, useState } from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';

interface AccordionProps {
  title: string;
}

const Accordion = ({ title, children }: PropsWithChildren<AccordionProps>) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className='border border-gray-100 hover:border-gray-200 !mb-4'>
      <button
        type='button'
        onClick={() => setCollapsed(!collapsed)}
        aria-expanded={!collapsed}
        className='!w-full flex justify-between items-center p-3 bg-gray-100'
      >
        <p className='text-left font-semibold text-blue-600 !w-5/6'>{title}</p>
        {collapsed ? (
          <PlusIcon className='!w-6' />
        ) : (
          <MinusIcon className='!w-6' />
        )}
      </button>
      <div role='region' className={collapsed ? 'hidden' : '!px-4 !py-6'}>
        {children}
      </div>
    </div>
  );
};

export default Accordion;
