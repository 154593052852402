import React, { useCallback, useEffect, useState } from 'react';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import { useTranslation } from 'react-i18next';
import toKebabCase from '../utils/toKebabCase.utils';
import Stepper from '../components/Stepper';
import { steps } from '../utils/stepNavUtils';
import Button, { ButtonTypes } from '../forms/Button';
import FormCompletion from '../components/FormCompletion';
import Files from '../components/Files';
import StepPagination from '../components/StepPagination';
import RequestDetails from '../components/RequestDetails';
import Steps from '../components/Steps';
import { useApi } from '../api/APIContext';
import { Avancement, DemandeGPAClientPayload } from '../api/methods/demandes';
import MessagesTable from '../components/MessagesTable';
import { navigateTo } from '../utils/navigation.utils';
import Spinner from '../components/Spinner';
import { Alert } from '../alerts/Alert';
import { useForm } from 'react-hook-form';
import Columns from '../components/containers/Columns';
import Form from '../forms/Form';
import RegisteredCheckbox from '../forms/registeredFields/RegisteredCheckbox';
import { messages } from '../api/api';
import { DemandeMessage } from '../api/methods/messages';

const Dashboard = () => {
  const api = useApi();
  const [messages, setMessages] = useState<DemandeMessage[] | null>(null);

  const [demande, setDemande] = useState<DemandeGPAClientPayload>();
  const [avancement, setAvancement] = useState<Avancement>();
  const [justSubmitted, setJustSubmitted] = useState(false);
  //all advancement keys are true
  const canSubmit =
    avancement &&
    Object.keys(avancement).every(
      (key) => !!avancement[key as keyof typeof avancement]
    );
  const { t } = useTranslation();
  const { handleSubmit, register, watch } = useForm();

  const [step, setStep] = useState(demande?.stage?.step || 0);

  const stepObjects: string[] = t('stepper.steps', {
    returnObjects: true,
  });
  const stepsData = steps(stepObjects, demande?.stage?.step ?? 0);
  // const { toPDF, targetRef } = usePDF({
  //   method: 'build',
  //   filename: 'change_request_signature.pdf',
  //   page: { margin: Margin.MEDIUM },
  // });

  // const closeDemande = (data: any) => {};

  const refreshDemande = useCallback(() => {
    api?.demandes
      .getActivedemande()
      .then((d) => {
        setDemande(d);
        if (!d) {
          return navigateTo('/portal');
        }
        api?.messages?.getMessages(d.id).then(setMessages);

        setStep(d.stage.step);
      })
      .catch(() => {
        return navigateTo('/portal');
      });
    api?.demandes?.getAvancement().then(setAvancement);
  }, [api?.demandes]);

  useEffect(() => {
    refreshDemande();
  }, [api, refreshDemande]);

  useEffect(() => {
    if (!api) return;
    if (demande?.stage?.step === 1 && step > 1) {
      api?.demandes?.pingDemande().then(refreshDemande);
    }
  }, [api, demande, refreshDemande, step]);

  const submitDemandestep = useCallback(() => {
    const matchingSteps = {
      2: 3,
      3: 6,
      4: 9,
      8: 9,
      5: 12,
      6: 15,
      7: 18,
    };

    if (
      !demande?.stage?.step ||
      !matchingSteps[demande?.stage?.step as keyof typeof matchingSteps]
    )
      return;

    api?.demandes
      ?.setAvancement(
        matchingSteps[demande?.stage?.step as keyof typeof matchingSteps]
      )
      .then(() => {
        setJustSubmitted(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
  }, [demande, step, api]);

  const closeDemande = async (data: any) => {
    await api?.demandes?.deleteDemande();
    refreshDemande();
  };

  if (!demande) {
    return <Spinner />;
  }

  return (
    <ScreenContainer>
      <SectionContainer>
        <h1 className='heading1'>{t('dashboard.title')}</h1>
        {!demande.isClosed &&
          (justSubmitted || demande?.stage?.status === 'submitted') && (
            <Alert type={'success'} message={t('submitted')} />
          )}

        <h2 className='heading2'>{t('dashboard.requestDetails')}</h2>
        <RequestDetails requestData={demande} />
        <h2 id={toKebabCase(t('dashboard.progressId'))} className='heading2'>
          {t('dashboard.stepperTitle')}
        </h2>
        <Stepper
          steps={stepsData}
          step={demande.stage.step}
          reqStatus={'draft'}
        />

        {step >= 2 && <MessagesTable demande={demande} />}
        <Steps step={step} />

        {step >= 2 && (
          <>
            <FormCompletion
              avancement={avancement}
              step={step}
              demande={demande}
            />

            <Files
              step={step}
              demandeStep={demande?.stage?.step}
              demandeId={demande.id}
              demandeIsClosed={demande.isClosed}
            />
            {!demande.isClosed &&
              !avancement?.blockSubmission &&
              step === demande?.stage?.step && (
                <SectionContainer className='!mb-28'>
                  <h2 className='heading2' id={'transmission'}>
                    {t('dashboard.transmission.title')}
                  </h2>
                  {avancement?.ramq === false && (
                    <Alert
                      type={'info'}
                      header={t('dashboard.transmission.todo')}
                      message={t('avancement.ramq')}
                      button={
                        <Button
                          type={ButtonTypes.button}
                          label={t('avancement.details')}
                          primary
                          compact
                          className={'!mt-4'}
                          onClick={() =>
                            (window.location.href = `/personalInfo?demandeId=${demande?.id}`)
                          }
                        />
                      }
                    />
                  )}
                  {avancement?.priorAuth === false && (
                    <Alert
                      type={'info'}
                      header={t('dashboard.transmission.todo')}
                      message={t('avancement.priorAuth')}
                      button={
                        <Button
                          type={ButtonTypes.button}
                          label={t('avancement.details')}
                          primary
                          compact
                          className={'!mt-4'}
                          onClick={() =>
                            (window.location.href = `/priorAuth?demandeId=${demande?.id}`)
                          }
                        />
                      }
                    />
                  )}
                  {avancement?.parentsLogic === false && (
                    <Alert
                      type={'info'}
                      header={t('dashboard.transmission.todo')}
                      message={t('avancement.twoParents')}
                      button={
                        <Button
                          type={ButtonTypes.button}
                          label={t('avancement.details')}
                          primary
                          compact
                          className={'!mt-4'}
                          onClick={() =>
                            (window.location.href = `/personalInfo?demandeId=${demande?.id}`)
                          }
                        />
                      }
                    />
                  )}
                  {avancement?.parentAuth === false && (
                    <Alert
                      type={'info'}
                      message={t('avancement.parentAuth')}
                      button={
                        <Button
                          type={ButtonTypes.button}
                          label={t('avancement.details')}
                          primary
                          compact
                          className={'!mt-4'}
                          onClick={() =>
                            (window.location.href = `/parentAuth?demandeId=${demande?.id}`)
                          }
                        />
                      }
                    />
                  )}
                  {avancement?.changeRequest === false && (
                    <Alert
                      type={'info'}
                      message={t('avancement.changeForm')}
                      button={
                        <Button
                          type={ButtonTypes.button}
                          label={t('avancement.details')}
                          primary
                          compact
                          className={'!mt-4'}
                          onClick={() =>
                            (window.location.href = `/changeRequest?demandeId=${demande?.id}`)
                          }
                        />
                      }
                    />
                  )}
                  <p>{t('dashboard.transmission.description')}</p>
                  {canSubmit && !justSubmitted && (
                    <div className='flex !w-full flex-col items-center !mt-8'>
                      <Button
                        onClick={submitDemandestep}
                        primary
                        label={t('dashboard.transmission.button')}
                      />
                    </div>
                  )}
                </SectionContainer>
              )}
          </>
        )}

        <StepPagination
          step={step}
          setStep={setStep}
          steps={stepObjects}
          anchor='stepTitle'
        />

        {demande?.stage?.step <= 2 && (!messages || messages?.length == 0) ? (
          <Columns numOfCols={2}>
            <div className='!mt-16 !mb-10'>
              <h2 className='heading2'>{t('dashboard.closeRequest.title')}</h2>
              <p>{t('dashboard.closeRequest.draftForm')}</p>
              <Form onSubmit={handleSubmit(closeDemande)}>
                <RegisteredCheckbox
                  name={'confirmClose'}
                  register={register}
                  label={t('dashboard.closeRequest.confirmClose')}
                />
                <Button
                  label={t('dashboard.closeRequest.closeRequestBtn')}
                  primary
                  type={ButtonTypes.submit}
                  disabled={!watch('confirmClose')}
                  className='shadow-none !mt-12 !px-12 bg-red-300 border-red-300 hover:bg-red-200 hover:border-red-200 disabled:bg-red-200 disabled:border-red-200'
                  compact
                />
              </Form>
            </div>
          </Columns>
        ) : (
          <></>
        )}
      </SectionContainer>
    </ScreenContainer>
  );
};

export default Dashboard;
