import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Form from '../forms/Form';
import RegisteredCheckbox from '../forms/registeredFields/RegisteredCheckbox';
import RegisteredTextField from '../forms/registeredFields/RegisteredTextField';
import Button, { ButtonTypes } from '../forms/Button';
import Columns from '../components/containers/Columns';
import RegisteredSelect from '../forms/registeredFields/RegisteredSelect';
import RegisteredTextArea from '../forms/registeredFields/RegisteredTextArea';
import { useState } from 'react';
import { Alert } from '../alerts/Alert';
import { useApi } from '../api/APIContext';
import { InfoGPACreation } from '../api/methods/infoGPA';
import BackButton from '../components/BackButton';
import { useFetchedSelectOptions } from '../forms/hooks/useFetchedSelectOptions';
import { goToActiveDemande } from '../utils/navigation.utils';
import { ArrowLongLeftIcon } from '@heroicons/react/24/solid';

const Contact = () => {
  const { t } = useTranslation();
  const { handleSubmit, register } = useForm();

  const [submitted, setSubmitted] = useState('');
  const api = useApi();

  const getSelectOptions = useFetchedSelectOptions((api) =>
    api.infoGPA.getFormTypes()
  );

  const submitFn = (data: any) => {
    const infoGPACreation: InfoGPACreation = {
      lastname: data.lastName,
      firstname: data.firstName,
      email: data.email,
      phone: data.phone,
      requesterType: parseInt(data.requesterType),
      infoType: parseInt(data.infoType),
      //nl2br
      message: data.message?.replace(/\n/g, '<br>'),
      consent: data.consent,
    };

    api.infoGPA.submitInfoGPA(infoGPACreation).then(async (res) => {
      if (res) setSubmitted(res.infoGPAId);
    });
  };

  return (
    <ScreenContainer>
      <Button
        label={t('common.back')}
        href={'/'}
        className='!mt-8'
        compact
        icon={<ArrowLongLeftIcon width={20} height={20} />}
      />

      {submitted ? (
        <SectionContainer>
          <h1 className='heading1'>{t('gpaConfirmation.title')}</h1>
          <Alert type='success' message={t('gpaConfirmation.successAlert')} />
        </SectionContainer>
      ) : (
        <>
          <SectionContainer>
            <h1 className='heading1'>{t('contact.intro.title')}</h1>
            <p>{t('contact.intro.description')}</p>
          </SectionContainer>
          <SectionContainer>
            <Form onSubmit={handleSubmit(submitFn)}>
              <SectionContainer>
                <Columns numOfCols={2}>
                  <RegisteredTextField
                    name='lastName'
                    register={register}
                    label={t('contact.form.lastName.label')}
                  />
                  <RegisteredTextField
                    name='firstName'
                    register={register}
                    label={t('contact.form.firstName.label')}
                  />
                </Columns>
                <Columns numOfCols={2}>
                  <RegisteredTextField
                    name={'email'}
                    register={register}
                    label={t('contact.form.email.label')}
                    placeholder={t('contact.form.email.placeholder')}
                  />
                  <RegisteredTextField
                    name={'phone'}
                    register={register}
                    label={t('contact.form.phone.label')}
                    placeholder={t('contact.form.phone.placeholder')}
                  />
                </Columns>
                <Columns numOfCols={2}>
                  <RegisteredSelect
                    name={'requesterType'}
                    register={register}
                    label={t('contact.form.youAre.label')}
                    options={getSelectOptions('requesterTypes')}
                    placeholder={t('common.forms.selectPlaceholder')}
                  />
                  <RegisteredSelect
                    name={'infoType'}
                    register={register}
                    label={t('contact.form.infoType.label')}
                    options={getSelectOptions('infoType')}
                    placeholder={t('common.forms.selectPlaceholder')}
                  />
                </Columns>
                {/*<Columns numOfCols={2}>*/}
                {/*  <RegisteredSelect*/}
                {/*    name={'country'}*/}
                {/*    register={register}*/}
                {/*    label={t('contact.form.country.label')}*/}
                {/*    options={countriesSelection}*/}
                {/*    placeholder={t('common.forms.selectPlaceholder')}*/}
                {/*  />*/}
                {/*  <RegisteredSelect*/}
                {/*    name={'residence'}*/}
                {/*    register={register}*/}
                {/*    label={t('contact.form.province.label')}*/}
                {/*    options={provincesSelection}*/}
                {/*    placeholder={t('common.forms.selectPlaceholder')}*/}
                {/*  />*/}
                {/*</Columns>*/}
                <RegisteredTextArea
                  name={'message'}
                  register={register}
                  title={t('contact.form.message.label')}
                  rows={8}
                  maxChars={5000}
                />
              </SectionContainer>
              <RegisteredCheckbox
                name={'consent'}
                register={register}
                label={t('contact.form.consent')}
              />

              <Button
                type={ButtonTypes.submit}
                className='!mt-14'
                primary
                label={t('contact.form.submit')}
              />
            </Form>
          </SectionContainer>
        </>
      )}
    </ScreenContainer>
  );
};

export default Contact;
