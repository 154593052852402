import r2wc from '@r2wc/react-to-web-component';
import App from './App';
import './index.css';
import './i18n';

const isRunningReactDev = process.env.REACT_APP_RUNNING_IN_DEV === 'true';

//Cette fonction exporte le composant principal (App) en Web Component
const wcGPAHQApp = r2wc(App, {
  props: {
    path: 'string',
    language: 'string',
    oauthclientid: 'string',
    oauthurl: 'string', // changed from 'oauthUrl'
    azfunctionsurl: 'string',
    appurl: 'string',
  },
  shadow: isRunningReactDev ? undefined : 'open',
});

class styledGPAHQApp extends wcGPAHQApp {
  constructor() {
    super();
    const elementById = document.getElementById('gpaHQStyle');
    if (elementById) {
      // Cast to  un-exported `ReactWebComponent` type
      (this as unknown as { container: HTMLElement }).container.append(
        elementById.cloneNode(true)
      );
    }
  }
}

customElements.define('gpa-hq-app', styledGPAHQApp);
