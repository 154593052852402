import { loadActiveDemandeId } from './demande.utils';

export const goBack = () => window.history.back();

export const navigateTo = (path: string) => (window.location.href = path);

export const getUrlParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const goToDemande = (demandeId = loadActiveDemandeId()) => {
  navigateTo(`/dashboard?demandeId=${demandeId}`);
};

export const goToActiveDemande = () => goToDemande();

export const scrollToTop = () =>
  window.scrollTo({ top: 0, behavior: 'smooth' });
