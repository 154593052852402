import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import Checkbox, { CheckboxProps } from '../Checkbox';

interface RegisteredCheckboxGroupProps<
  RegisteredCheckboxGroupType extends FieldValues,
> extends CheckboxProps {
  name: Path<RegisteredCheckboxGroupType>;
  options: { label: string; name: string; description?: string }[];
  register: UseFormRegister<RegisteredCheckboxGroupType>;
  errorMessage?: string;
}

const RegisteredCheckboxGroup = <
  RegisteredCheckboxGroupType extends FieldValues,
>({
  register,
  options,
  errorMessage,
  ...props
}: RegisteredCheckboxGroupProps<RegisteredCheckboxGroupType>) => {
  const { ref, onChange, ...registerProps } = register(props.name);

  return (
    <>
      <fieldset>
        <legend className='font-bold paragraph'>
          {props.label}
          {props.required && <span className='text-red-300'>*&nbsp;</span>}
        </legend>
        {options.map((option, index) => (
          <Checkbox
            key={index}
            label={option.label}
            {...registerProps}
            checkboxRef={ref}
            onChange={onChange}
            value={option.name}
            description={option.description}
            parentHasError={!!errorMessage}
          />
        ))}
      </fieldset>

      {errorMessage && (
        <span className='!mt-2 text-sm font-bold text-red-300'>
          {errorMessage}
        </span>
      )}
    </>
  );
};

export default RegisteredCheckboxGroup;
