import { useTranslation } from 'react-i18next';
import ScreenContainer from '../components/containers/ScreenContainer';
import Columns from '../components/containers/Columns';
import SectionContainer from '../components/containers/SectionContainer';
import Button, { ButtonTypes } from '../forms/Button';
import RegisteredTextArea from '../forms/registeredFields/RegisteredTextArea';
import { useForm } from 'react-hook-form';
import Form from '../forms/Form';
import { zodResolver } from '@hookform/resolvers/zod';
import BackButton from '../components/BackButton';
import { useApi } from '../api/APIContext';
import { useEffect, useState } from 'react';
import { DemandeMessage } from '../api/methods/messages';
import { getUrlParam, goBack, goToDemande } from '../utils/navigation.utils';
import Spinner from '../components/Spinner';
import ReactMarkdown from 'react-markdown';
import { getFormattedDateTime } from '../lib/dateformat';
import {
  generateMessageReplyFormSchema,
  formatMessageSender,
  isSqinEmail,
} from '../utils/demande-message.utils';
import FilesListTable from '../components/containers/FilesListTable';

const MessageDetails = () => {
  const { i18n, t } = useTranslation();
  const api = useApi();
  const [message, setMessage] = useState<DemandeMessage | null>(null);
  const [formattedSender, setFormattedSender] = useState<string | null>(null);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(generateMessageReplyFormSchema(i18n.language)),
    defaultValues: { message: '' },
  });

  const messageId = getUrlParam('messageId');

  useEffect(() => {
    if (messageId) {
      api.messages
        .getMessageById(messageId)
        .then((message) => {
          setMessage(message);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [api, messageId]);

  useEffect(() => {
    if (message && isSqinEmail(message.sender)) {
      api.demandes.getActivedemande(message.demandeId).then((d) => {
        setFormattedSender(formatMessageSender(d, message));
      });
    }
  }, [api, message]);

  if (!messageId) {
    goBack();
    return null;
  }

  const submitFn = (data: { message: string }) =>
    api.messages
      .replyToMessage({
        message: data.message,
        replyToMessageId: messageId,
      })
      .then(() => goToDemande(message!.demandeId));

  if (!message) {
    return <Spinner />;
  }

  const { fileNumber, demandeId, subject, createdAt, sender } = message;

  return (
    <ScreenContainer>
      <BackButton />

      <h1 className='heading1'>{t('demandeMessages.title')}</h1>
      <Form onSubmit={handleSubmit(submitFn)}>
        <SectionContainer>
          <Columns className='sm:basis-5/6 pb-4 border-b' numOfCols={2}>
            <div>
              <p className='font-bold'>{t('demandeMessages.sentBy')}</p>
              <p>
                {formattedSender
                  ? formattedSender
                  : isSqinEmail(sender)
                    ? ''
                    : sender}
              </p>
              {message.inReplyToMessageId && (
                <p className='!mt-1'>
                  {t('demandeMessages.inReplyTo')}
                  &nbsp;
                  <a
                    className='underline'
                    href={`/message?messageId=${message.inReplyToMessageId}?demandeId=${demandeId}`}
                  >
                    {t('demandeMessages.thisMessage')}
                  </a>
                </p>
              )}
            </div>
            <div>
              <p className='font-bold'>{t('demandeMessages.reqRelated')}</p>
              <a
                className='underline'
                href={`/dashboard?demandeId=${demandeId}`}
              >
                <p>{fileNumber}</p>
              </a>
            </div>
            <div>
              <p className='font-bold'>{t('demandeMessages.sentOn')}</p>
              <p>{getFormattedDateTime(createdAt, i18n.language)}</p>
            </div>
            <div>
              <p className='font-bold'>{t('demandeMessages.subject')}</p>
              <p>{subject}</p>
            </div>
          </Columns>
          <SectionContainer>
            <ReactMarkdown
              components={{
                p: ({ ...props }) => (
                  <p {...props} className='mb-5'>
                    {props.children}
                  </p>
                ),
              }}
            >
              {message.message}
            </ReactMarkdown>
          </SectionContainer>

          <hr />
          {message.files && message.files.length > 0 && (
            <SectionContainer>
              <FilesListTable
                titles={['Fichiers attachés']}
                files={message.files}
              />
              <p className='italic mt-2 text-gray-400'>
                Les fichiers joints aux messages reçus sont automatiquement
                ajoutés aux fichiers de votre demande.
              </p>
            </SectionContainer>
          )}
          <h2 className={'heading2'}>{t('demandeMessages.reply')}</h2>
          <RegisteredTextArea
            name='message'
            register={register}
            title={t('demandeMessages.yourMessage')}
            rows={8}
            maxChars={5000}
            errorMessage={errors?.message?.message?.toString()}
          />
          <Button
            primary
            type={ButtonTypes.submit}
            disabled={isSubmitting}
            loading={isSubmitting}
            label={t('demandeMessages.sendMessage')}
            className='inline mr-auto'
          />
        </SectionContainer>
      </Form>
    </ScreenContainer>
  );
};

export default MessageDetails;
