import { TFunction } from 'i18next';
import { z } from 'zod';
import { translateLanguage } from './translateLanguage.utils';
import {
  dateString,
  errorMessageDefinition,
  optionalDateString,
  requiredString,
} from './zod-validators';

const booleanArray = ['yes', 'no', ''] as const;

const ParentMaterial = ['ovocyte', 'sperm', 'none'] as const;

const DonorMaterial = ['ovocyte', 'sperm', 'spermAndOvocyte', 'none'] as const;

export const parentAuthSchema = (locale: string = 'fr') =>
  z.object({
    changes: z
      .discriminatedUnion('status', [
        z.object({
          status: z.literal(''),
          hasChanges: z.enum(booleanArray, {
            message: translateLanguage('common.forms.required', locale),
          }),
        }),
        z.object({
          status: z.literal('undeterminedChanges'),
          hasChanges: z.enum(booleanArray, {
            message: translateLanguage('common.forms.required', locale),
          }),
          changes: z
            .string()
            .array()
            .min(1, {
              message: translateLanguage('common.forms.required', locale),
            }),
        }),
        z.object({
          status: z.literal('noChanges'),
          hasChanges: z.literal('no', {
            message: translateLanguage('common.forms.required', locale),
          }),
        }),

        z.object({
          status: z.literal('hasMaritalStatusChanges'),
          hasChanges: z.enum(booleanArray, {
            message: translateLanguage('common.forms.required', locale),
          }),
          changes: z
            .string()
            .array()
            .min(1, {
              message: translateLanguage('common.forms.required', locale),
            }),
          changeAttachedDoc: requiredString(locale),
        }),
        z.object({
          status: z.literal('hasResidenceChanges'),
          hasChanges: z.enum(booleanArray, {
            message: translateLanguage('common.forms.required', locale),
          }),
          changes: z
            .string()
            .array()
            .min(1, {
              message: translateLanguage('common.forms.required', locale),
            }),
          residenceChangeDoc: requiredString(locale),
          parentChangeExplanation: requiredString(locale),
        }),
        z.object({
          status: z.literal('hasMaritalAndResidenceChanges'),
          hasChanges: z.enum(booleanArray),
          changes: z
            .string()
            .array()
            .min(1, {
              message: translateLanguage('common.forms.required', locale),
            }),
          changeAttachedDoc: requiredString(locale),
          residenceChangeDoc: requiredString(locale),
          parentChangeExplanation: requiredString(locale),
        }),
      ])
      .superRefine((val, ctx) => {
        if (val.status === '' && !val.hasChanges) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['hasChanges'],
            message: translateLanguage('common.forms.required', locale),
          });
        }
      }),

    surrogateRelationship: z
      .discriminatedUnion(
        'maritalStatus',
        [
          z.object({
            maritalStatus: z.literal('', errorMessageDefinition(locale)),
          }),
          z.object({
            maritalStatus: z.literal('single', errorMessageDefinition(locale)),
          }),
          z.object({
            maritalStatus: z.literal(
              'civilUnion',
              errorMessageDefinition(locale)
            ),
          }),
          z.object({
            maritalStatus: z.literal(
              'commonLaw',
              errorMessageDefinition(locale)
            ),
          }),
          z.object({
            maritalStatus: z.literal('married', errorMessageDefinition(locale)),
          }),
        ],
        {
          errorMap: () => errorMessageDefinition(locale),
        }
      )
      .superRefine((val, ctx) => {
        if (val.maritalStatus === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['maritalStatus'],
            message: translateLanguage('common.forms.required', locale),
          });
        }
      }),

    surrogate: z.object({
      firstName: requiredString(locale),
      lastName: requiredString(locale),
      dob: dateString(locale),
      address: requiredString(locale),
      city: requiredString(locale),
      state: requiredString(locale),
      postalCode: requiredString(locale),
      telephone: requiredString(locale),
    }),

    documents: z.object({
      idDocType: requiredString(locale),
      idDocExpiration: optionalDateString(locale),
      addressDocType: requiredString(locale),
      addressDocExpiration: optionalDateString(locale),
    }),

    altruism: z.literal(true, {
      errorMap: () => errorMessageDefinition(locale),
    }),

    ethnicOrigin: requiredString(locale),
    education: requiredString(locale),
    diplomas: requiredString(locale),
    profession: requiredString(locale),

    height: requiredString(locale),
    eyeColour: requiredString(locale),
    skinColour: requiredString(locale),
    hairColour: requiredString(locale),
    personalityTraits: requiredString(locale),

    surrogateMaterialUsed: requiredString(locale),

    reproductiveMaterial: z.discriminatedUnion('needsDonationMaterial', [
      z.object({
        needsDonationMaterial: z.literal('no', errorMessageDefinition(locale)),
      }),
      z.object({
        needsDonationMaterial: z.literal('yes', errorMessageDefinition(locale)),
        donationMaterial: z.enum(DonorMaterial, errorMessageDefinition(locale)),
      }),
    ]),

    reproductiveMaterialDocExpiration: optionalDateString(locale),

    agreement: z.object({
      agreementDate: requiredString(locale),
      language: z
        .discriminatedUnion('isInFrench', [
          z.object({
            isInFrench: z.literal(''),
          }),
          z.object({
            isInFrench: z.literal('yes'),
          }),
          z.object({
            isInFrench: z.literal('no'),
            agreementLanguage: requiredString(locale),
          }),
        ])
        .superRefine((val, ctx) => {
          if (val.isInFrench === '') {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['isInFrench'],
              message: translateLanguage('common.forms.required', locale),
            });
          }
        }),
      reimbursement: z
        .discriminatedUnion('isReimbursed', [
          z.object({
            isReimbursed: z.literal(''),
          }),
          z.object({
            isReimbursed: z.literal('yes'),
          }),
          z.object({
            isReimbursed: z.literal('no'),
            signDate: requiredString(locale), // This doesn't currently do any date validation, might need to be updated
          }),
        ])
        .superRefine((val, ctx) => {
          if (val.isReimbursed === '') {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['isReimbursed'],
              message: translateLanguage('common.forms.required', locale),
            });
          }
        }),
      authorizationSignDate: requiredString(locale),
    }),
  });

export type ParentAuthForm = z.infer<ReturnType<typeof parentAuthSchema>>;

export const getParentReproductiveMaterialUsedOptions = (
  t: TFunction<'translation', undefined>,
  surrogateOvocyte?: boolean
) =>
  [
    {
      value: 'sperm',
      label: t(
        'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.parent.sperm'
      ),
    },
    {
      value: 'ovocyte',
      label: t(
        'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.parent.ovocyte'
      ),
    },
    {
      value: 'none',
      label: t(
        'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.parent.none'
      ),
    },
  ].filter((e) => (surrogateOvocyte ? e.value !== 'ovocyte' : true));

export const getGametesOptions = (
  surrogateOvocyte: boolean,
  spermatozoide: boolean,
  t?: TFunction<'translation', undefined>
) =>
  [
    {
      value: 'sperm',
      label: t
        ? t(
            'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.donation.options.sperm'
          )
        : 'sperm',
    },
    {
      value: 'ovocyte',
      label: t
        ? t(
            'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.donation.options.ovocyte'
          )
        : 'ovocyte',
    },
    {
      value: 'spermAndOvocyte',
      label: t
        ? t(
            'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.donation.options.spermAndOvocyte'
          )
        : 'spermAndOvocyte',
    },
  ]
    .filter((e) => (surrogateOvocyte ? e.value !== 'ovocyte' : true))
    .filter((e) => (spermatozoide ? e.value !== 'sperm' : true))
    .filter((e) =>
      spermatozoide || surrogateOvocyte ? e.value !== 'spermAndOvocyte' : true
    );
