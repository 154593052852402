declare var Toastify: any;

export function toast(message: string, type?: 'success' | 'error') {
  const style = {
    color: 'white',
    background:
      type === undefined
        ? '#095797'
        : type === 'success'
          ? '#4f813d'
          : '#cb381f',
    'box-shadow': '0 4px 16px rgba(34, 54, 84, 0.24)',
    'font-family': 'Open Sans',
    'font-weight': 'semibold',
    'font-size': '14px',
    'line-height': '20px',
    padding: '16px',
  };

  Toastify({
    text: message,
    duration: 4000,
    gravity: 'bottom', // `top` or `bottom`
    position: 'right', // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    style,
  }).showToast();
}
