import { elementToSVG, inlineResources } from 'dom-to-svg';
import { jsPDF } from 'jspdf';
import 'svg2pdf.js';

const readFileAsDataURL = (blob: Blob): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64 = reader.result?.toString().split(',')[1];

      resolve(base64); // resolve with base64 data
    };
    reader.onerror = reject; // reject on error
  });
};

export const generatePDF = async (pdfSelector?: string) => {
  const gpaRoot = document.querySelector('gpa-hq-app');
  const shadowRoot = gpaRoot?.shadowRoot ?? document;
  const element = shadowRoot?.querySelector(pdfSelector ?? '.exportable-form');

  if (!element) {
    return;
  }
  const clone = element.cloneNode(true) as HTMLElement;

  // Copy values from original form elements to the clone
  const originalInputs = element.querySelectorAll<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >('input, select, textarea');
  const clonedInputs = clone.querySelectorAll<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >('input, select, textarea');

  originalInputs.forEach((originalInput, index) => {
    const clonedInput = clonedInputs[index];

    if (originalInput instanceof HTMLInputElement) {
      if (originalInput.type === 'checkbox' || originalInput.type === 'radio') {
        (clonedInput as HTMLInputElement).checked = originalInput.checked;
      } else {
        clonedInput.value = originalInput.value;
      }
    } else if (
      originalInput instanceof HTMLSelectElement ||
      originalInput instanceof HTMLTextAreaElement
    ) {
      clonedInput.value = originalInput.value;
    }
  });

  // process cloned element
  // Replace all <select> elements with a <p> containing the visible label
  clone.querySelectorAll<HTMLSelectElement>('select').forEach((select) => {
    const selectedOptions = Array.from(select.selectedOptions)
      .map((option) => option.text)
      .join(', ');
    const p = document.createElement('p');
    p.textContent = selectedOptions || '';
    select.parentNode?.replaceChild(p, select);
  });

  // Replace all <input> and <textarea> elements with a <p> containing the inner text
  clone
    .querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea')
    .forEach((input) => {
      const p = document.createElement('p');
      if (
        input instanceof HTMLInputElement &&
        (input.type === 'radio' || input.type === 'checkbox')
      ) {
        // Do nothing here, handled separately below
      } else {
        p.textContent = input.value || '';
        input.parentNode?.replaceChild(p, input);
      }
    });

  // Append the modified clone to the document for printing or further processing
  document.body.appendChild(clone);

  const svgDocument = elementToSVG(clone);

  await inlineResources(svgDocument.documentElement);

  //delete all <text> elements that are child of   <g  data-stacking-layer="inFlowInlineLevelNonPositionedDescendants"> in the svg
  const textElements = svgDocument.querySelectorAll(
    'g:is([role="radio"], [role="checkbox"]) g[data-stacking-layer="inFlowInlineLevelNonPositionedDescendants"] text'
  );
  textElements.forEach((textElement) => {
    textElement.remove();
  });

  const svgString = new XMLSerializer().serializeToString(svgDocument);

  //svg string is a full svg file
  const svgcontainer = document.createElement('svgContainer');

  svgcontainer.innerHTML = svgString;

  const svgElement = svgcontainer.querySelector('svg') as Element;

  //add element to body
  document.body.appendChild(svgElement);
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'in',
    format: [
      8,
      //proportional to svg height
      (svgElement.getBoundingClientRect().height * 8) /
        svgElement.getBoundingClientRect().width,
    ],
  });

  return doc
    .svg(svgElement, {
      x: 0.5,
      y: 0,
      width: doc.internal.pageSize.getWidth() - 1,
      height: doc.internal.pageSize.getHeight() - 1,
    })
    .then(() => {
      return doc.output('arraybuffer');
    });
};

export const downloadHTML = (documentTitle: string) => {
  const element = document?.querySelector('.exportable-form');
  if (!element) {
    return;
  }
  const inputs = element.querySelectorAll('input, textarea, select');
  // const labels = document.querySelectorAll('label');

  inputs.forEach((input) => {
    if (input instanceof HTMLInputElement) {
      if (input.type === 'checkbox' || input.type === 'radio') {
        input.checked
          ? input.setAttribute('checked', 'true')
          : input.removeAttribute('checked');
      } else {
        replaceElementWithText(input, `${input.value}`);
      }
    } else if (input instanceof HTMLTextAreaElement) {
      replaceElementWithText(input, `${input.value}`);
    } else if (input instanceof HTMLSelectElement) {
      const options = Array.from(input.options);
      const selectedOption = options.find((o) => o.value === input.value);
      console.log(selectedOption, input.options, input.value);
      replaceElementWithText(
        input,
        `${selectedOption ? selectedOption.innerText : ''}`
      );
    }
  });

  // function getLabelForInput(
  //   input: HTMLElement,
  //   labels: NodeListOf<HTMLLabelElement>
  // ): string {
  //   const id = input.id;
  //   if (id) {
  //     const label = Array.from(labels).find((lbl) => lbl.htmlFor === id);
  //     if (label) {
  //       return label.textContent || '';
  //     }
  //   }
  //   return '';
  // }

  function replaceElementWithText(element: HTMLElement, text: string) {
    const textNode = document.createTextNode(text);
    element.replaceWith(textNode);
  }
  const styleSheets = document.styleSheets;
  let allStyles = '';
  for (let i = 0; i < styleSheets.length; i++) {
    try {
      const styleSheet = styleSheets[i];
      const rules = styleSheet.cssRules;
      for (let j = 0; j < rules.length; j++) {
        const rule = rules[j];
        allStyles += rule.cssText + '\n';
      }
    } catch (e) {
      console.error(e);
    }
  }

  const htmlString = `
      <!DOCTYPE html>
      <html>
        <head>
        <style>${allStyles}</style>
          <title>${documentTitle || 'GPA HQ'}</title>
        </head>
        <body>
<div class="w-full h-4 font-bold text-md text-center text-red-300">${new Date().toISOString()}</div>
${element.outerHTML}</body>
      </html>
    `;

  return new Blob([htmlString], { type: 'text/html' });
};
