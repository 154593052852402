import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import Button, { ButtonTypes } from '../forms/Button';
import Form from '../forms/Form';
import RegisteredTextArea from '../forms/registeredFields/RegisteredTextArea';
import RegisteredTextField from '../forms/registeredFields/RegisteredTextField';
import { getActiveDemandeId } from '../utils/demande.utils';
import { useApi } from '../api/APIContext';
import { goBack } from '../utils/navigation.utils';
import BackButton from '../components/BackButton';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  newMessageDefaultValues,
  newMessageSchema,
} from '../utils/demande-message.utils';
import i18n from '../i18n';

const NewMessage = () => {
  const { t } = useTranslation();
  const api = useApi();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(newMessageSchema(i18n.language)),
    defaultValues: newMessageDefaultValues,
  });

  const demandeId = getActiveDemandeId();

  const submitFn = async (data: any) => {
    const payload = {
      ...data,
      demandeId,
    };

    await api.messages.createMessage(payload);

    goBack();
  };

  return (
    <ScreenContainer>
      <BackButton />

      <h1 className='heading1'>{t('demandeMessages.sendNewMessage')}</h1>
      <SectionContainer>
        <Form onSubmit={handleSubmit(submitFn)}>
          <RegisteredTextField
            name={'subject'}
            register={register}
            label={t('demandeMessages.subject')}
            errorMessage={errors.subject?.message}
          />
          <RegisteredTextArea
            rows={8}
            name={'message'}
            register={register}
            title={t('demandeMessages.yourMessage')}
            maxChars={5000}
            errorMessage={errors.message?.message}
          />
          <Button
            primary
            loading={isSubmitting}
            disabled={isSubmitting}
            label={t('demandeMessages.sendMessage')}
            type={ButtonTypes.submit}
          />
        </Form>
      </SectionContainer>
    </ScreenContainer>
  );
};

export default NewMessage;
