import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useApi } from '../api/APIContext';
import { DemandeGPAClientPayload } from '../api/methods/demandes';
import Pill from '../components/Pill';
import Columns from '../components/containers/Columns';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import Button, { ButtonTypes } from '../forms/Button';
import Form from '../forms/Form';
import RegisteredCheckbox from '../forms/registeredFields/RegisteredCheckbox';
import RegisteredTextArea from '../forms/registeredFields/RegisteredTextArea';
import RegisteredTextField from '../forms/registeredFields/RegisteredTextField';
import {
  dataverseFetch,
  dataverseFetchWithNotification,
} from '../api/methods/http';
import { getFormattedDateTime } from '../lib/dateformat';
import isEqual from 'lodash/isEqual';
import {
  determineSignatureState,
  getDemandeParents,
  uploadFormHtml,
  uploadPDFForm,
  waitSeconds,
} from '../utils/authFormCommon.utils';
import BackButton from '../components/BackButton';

export interface ChangeRequestPayload {
  id?: string;
  changeSurrogate?: boolean;
  changeDesignatedState?: boolean;
  changeParentStatus?: boolean;
  changeAgreement?: boolean;
  other?: boolean;
  yourMessage?: string;
  specify?: string;
  signed_pi1?: string;
  signed_pi2?: string;
  signConsent: boolean;
}

const ChangeRequest = () => {
  const { t, i18n } = useTranslation();
  const api = useApi();
  const [demande, setDemande] = useState<DemandeGPAClientPayload>();

  const [changeRequest, setChangeRequest] = useState<ChangeRequestPayload>();
  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: changeRequest,
  });

  const { selfParent, otherParent } = getDemandeParents(demande);
  const signatureState = determineSignatureState(demande, changeRequest);
  const { selfParentHasSigned, otherParentHasSigned, someoneHasSigned } =
    signatureState;

  const setCR = useCallback(
    async (cr: ChangeRequestPayload) => {
      setChangeRequest(cr);
      reset(cr);
      if (!demande) {
        return;
      }
      const newSignatureState = determineSignatureState(demande, cr);
      const { numberOfMissingSignatures } = newSignatureState;

      if (numberOfMissingSignatures === 0) {
        await waitSeconds(0.1);
        await uploadPDFForm(
          '.exportable-form',
          api,
          demande,
          t('changeRequest.title'),
          3
        );
        window.location.href = `/signed?demandeId=${demande.id}`;
      }
    },
    [api, demande, reset, t]
  );

  useEffect(() => {
    api?.demandes
      .getActivedemande()
      .then((d) => {
        if (!isEqual(d, demande)) {
          setDemande(d);
          if (!d) {
            return (window.location.href = '/portal');
          }
          dataverseFetch<ChangeRequestPayload>(
            `/demandes/${d.id}/changerequest`
          ).then((d) => {
            if (Object.keys(d).length !== 0) {
              setCR(d);
            }
          });
        }
      })
      .catch(() => {
        return (window.location.href = '/portal');
      });
  }, [api, demande, setCR]);

  const submitFn = async (data: any) => {
    // console.log(data);
    const response = await dataverseFetchWithNotification<ChangeRequestPayload>(
      `/demandes/${demande?.id}/changerequest`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
      }
    );

    setCR(response);
    // toPDF();
  };
  if (!demande) return <></>;
  return (
    <ScreenContainer>
      <BackButton />

      <SectionContainer className={'exportable-form'}>
        <h1 className='heading1'>{t('changeRequest.title')}</h1>
        {/*<RequestDetails requestData={requestData} />*/}

        <Form onSubmit={handleSubmit(submitFn)}>
          <h2 className='heading2 md:!w-9/12'>
            {t('changeRequest.changeAgreement')}
          </h2>
          <p>
            {t(
              `changeRequest.${!demande.parents?.[1] ? 'oneIdentifier' : 'twoIdentifiers'}.informDGPAHQ`
            )}
          </p>
          <fieldset disabled={someoneHasSigned}>
            <SectionContainer className='pl-6'>
              <RegisteredCheckbox
                name={'changeSurrogate'}
                register={register}
                label={t('changeRequest.form.changeSurrogate')}
              />
              <RegisteredCheckbox
                name={'changeDesignatedState'}
                register={register}
                label={t('changeRequest.form.changeDesignatedState')}
              />
              <RegisteredCheckbox
                name={'changeParentStatus'}
                register={register}
                label={t('changeRequest.form.changeParentStatus')}
              />
              <RegisteredCheckbox
                name={'changeAgreement'}
                register={register}
                label={t('changeRequest.form.changeAgreement')}
              />
              <RegisteredCheckbox
                name={'other'}
                register={register}
                label={t('changeRequest.form.other')}
              />
              {watch('other') && (
                <Columns numOfCols={2}>
                  <RegisteredTextField
                    name={'specify'}
                    register={register}
                    label={t('changeRequest.form.specify')}
                    required={watch('other')}
                  />
                </Columns>
              )}
            </SectionContainer>
            <RegisteredTextArea
              name={'yourMessage'}
              register={register}
              required
              title={t('changeRequest.form.explainClearReq')}
              rows={4}
            />
          </fieldset>
          <ReactMarkdown className='!my-6 whitespace-pre-wrap'>
            {t(
              `changeRequest.${!demande.parents?.[1] ? 'oneIdentifier' : 'twoIdentifiers'}.notesOnDGPAHQ`
            )}
          </ReactMarkdown>
          <h3 className='!mt-20 heading3'>
            {t('changeRequest.signatureForm')}
          </h3>

          <Columns numOfCols={2}>
            <div className='flex flex-col'>
              <h4 className={'heading4 !mb-2 space-x-4'}>
                <span>
                  {selfParent?.firstName} {selfParent?.lastName}
                </span>
                {selfParentHasSigned ? (
                  <Pill
                    type={'success'}
                    text={t('common.signature.signedLabel')}
                  />
                ) : (
                  <Pill
                    type={'warning'}
                    text={t('common.signature.toSignLabel')}
                  />
                )}
              </h4>

              {!selfParentHasSigned ? (
                <>
                  <RegisteredCheckbox
                    label={t('common.signature.clickToSign')}
                    name='signConsent'
                    register={register}
                  />
                  <Button
                    type={ButtonTypes.submit}
                    loading={isSubmitting}
                    disabled={isSubmitting || !watch('signConsent')}
                    label={t('changeRequest.button')}
                    className='inline mr-auto'
                    primary
                  />
                </>
              ) : (
                <>
                  <p className={'!mb-8'}>
                    {t('common.signature.signed', {
                      name: `${selfParent?.firstName} ${selfParent?.lastName}`,
                      time: getFormattedDateTime(
                        selfParentHasSigned,
                        i18n.language
                      ),
                    })}
                    <BackButton />
                  </p>
                </>
              )}
            </div>
            {otherParent && (
              <>
                <div className='flex flex-col'>
                  <h4 className={'heading4 !mb-2 space-x-4'}>
                    <span>
                      {otherParent?.firstName} {otherParent?.lastName}
                    </span>
                    {otherParentHasSigned ? (
                      <Pill
                        type={'success'}
                        text={t('common.signature.signedLabel')}
                      />
                    ) : (
                      <Pill
                        type={'warning'}
                        text={t('common.signature.toSignLabel')}
                      />
                    )}
                  </h4>
                  {otherParentHasSigned ? (
                    <p className={'!mb-8'}>
                      {t('common.signature.signed', {
                        name: `${otherParent?.firstName} ${otherParent?.lastName}`,
                        time: getFormattedDateTime(
                          otherParentHasSigned,
                          i18n.language
                        ),
                      })}
                    </p>
                  ) : (
                    <p className={'!mb-8'}>
                      {t('common.signature.mustSign', {
                        name: `${otherParent?.firstName} ${otherParent?.lastName}`,
                      })}
                    </p>
                  )}
                </div>
              </>
            )}
          </Columns>
        </Form>
      </SectionContainer>
    </ScreenContainer>
  );
};

export default ChangeRequest;
