import { ArrowLongRightIcon } from '@heroicons/react/24/solid';

interface LinkListProps {
  title: string;
  links: { label: string; href: string }[];
  headingLevel?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const LinkList = ({ title, links, headingLevel = 'h2' }: LinkListProps) => {
  const HeadingElement = headingLevel;

  return (
    <>
      <HeadingElement className='!px-2 !py-3 font-bold border-t border-b paragraph'>
        {title}
      </HeadingElement>
      <ul className='pl-0 !mb-8 list-none'>
        {links.map((link, index) => (
          <li className='!px-2 !py-3 border-b ' key={index}>
            <a
              href={link.href}
              target={link?.href?.startsWith('http') ? '_blank' : undefined}
              rel={
                link?.href?.startsWith('http')
                  ? 'noopener noreferrer nofollow'
                  : undefined
              }
              className='flex justify-between'
            >
              {link.label}
              <ArrowLongRightIcon className='!w-6' />
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default LinkList;
