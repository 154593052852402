import { LegacyRef } from 'react';
import classNames from '../utils/classList.utils';
import { Size, sizeToWidth } from '../utils/fieldSize.utils';
import { useTranslation } from 'react-i18next';

export interface TextAreaProps {
  name: string;
  title: string;
  size?: Size;
  maxChars?: number;
  rows?: number;
  textAreaRef?: LegacyRef<HTMLTextAreaElement>;
  required?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}

const TextArea = ({
  title,
  maxChars,
  textAreaRef,
  size = 'full',
  rows = 2,
  required,
  errorMessage,
  ...props
}: TextAreaProps) => {
  const { t } = useTranslation();
  const { name } = props;
  return (
    <div className='!mb-8'>
      <label htmlFor={name} className={classNames('block font-bold !mb-2')}>
        {title}
        {required && <span className='font-bold text-red-300'>&nbsp;*</span>}
      </label>
      <textarea
        ref={textAreaRef}
        id={name}
        maxLength={maxChars}
        rows={rows}
        {...props}
        className={classNames(
          sizeToWidth(size),
          'paragraph border !w-full !py-2 !px-3 text-gray-700 leading-tight border-gray-300 focus:outline-offset-2 focus:outline-2 focus:outline-blue-300',
          errorMessage ? 'border-red-300 border-2' : 'border-gray-300'
        )}
      />
      <div
        className={classNames(
          'flex',
          errorMessage ? 'justify-between' : 'justify-end'
        )}
      >
        {errorMessage && (
          <p className='text-sm font-bold text-red-300'>{errorMessage}</p>
        )}
        {maxChars && (
          <p className='text-sm text-gray-400'>
            {t('common.maximumCharacters', { maxChars })}
          </p>
        )}
      </div>
    </div>
  );
};

export default TextArea;
