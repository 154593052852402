import i18n from '../i18n';
import { translateLanguage } from '../utils/translateLanguage.utils';
import { toast } from '../lib/customToast';

const language = i18n.language;

const isMessageKey = (s: string) => /^[a-z.]+$/.test(s);

export const apiSuccessHandler = (
  messageKey = 'common.toasts.success'
): void => {
  toast(`${translateLanguage(messageKey, language)}`, 'success');
};

export const apiErrorHandler = (e?: unknown): never => {
  let errorMessage: string = '';

  toast(`${translateLanguage('common.toasts.error', language)}`, 'error');
  throw new Error(errorMessage);
};
