import { LegacyRef, PropsWithChildren } from 'react';
import classNames from '../../utils/classList.utils';

interface ContainerProps {
  className?: string;
  ref?: LegacyRef<HTMLDivElement>;
}

const ScreenContainer = ({
  className = '',
  children,
  ref,
}: PropsWithChildren<ContainerProps>) => {
  return (
    <div
      ref={ref}
      className={classNames('!mx-auto !px-4 sm:!px-6 lg:!px-8 pb-8', className)}
    >
      {children}
    </div>
  );
};

export default ScreenContainer;
