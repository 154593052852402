import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import Button from '../forms/Button';
import Spinner from '../components/Spinner';
import { useEffect } from 'react';
import { useApi } from '../api/APIContext';
import { getActiveDemandeId } from '../utils/demande.utils';

const FormSignedconfirmation = () => {
  const api = useApi();

  return (
    <ScreenContainer>
      <SectionContainer>
        <h1 className='heading1'>Formulaire signé et transmis</h1>
        <p>
          Vous devez maintenant retourner à votre demande pour vous assurer que
          tous vos documents jusitficatifs ont été téléversés.
        </p>
        <p>
          Vous pourrez en tout temps télécharger une copie du formulaire signé
          dans l'espace "Formulaires" de votre demande.
        </p>
      </SectionContainer>
      <SectionContainer>
        <Button
          label={'Retourner à la demande'}
          href={`/dashboard?demandeId=${getActiveDemandeId()}`}
        />
      </SectionContainer>
    </ScreenContainer>
  );
};

export default FormSignedconfirmation;
