import dayjs from 'dayjs';
/**
 * Must match the date-fns available formats
 */
const DateFormats = {
  'yyyy/MM/dd': '/',
  'yyyy-MM-dd': '-',
  'yyyy MM dd': ' ',
} as const;

/**
 *
 * @param format Format to implement on the input date field (Must match the date-fns available formats)
 * @param event Target event to be handled for date
 * @returns An object containing getFormattedDate(as specified by the `format`) function and handleSelectionRange function
 */
export const handleDateInput = (
  format: keyof typeof DateFormats | string,
  event: React.FocusEvent<HTMLInputElement, Element>
) => {
  const value = event.target.value;
  const selectionPosition = event.target.selectionStart;

  switch (format) {
    case 'yyyy-MM-dd':
    case 'yyyy/MM/dd':
    case 'yyyy MM dd': {
      const getFormattedDate = () => {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return '';

        const formatString = DateFormats[format];
        // clean the input for any non-digit values. Regex = /[^[0-9]/g or /[^\d]/g
        const cleanedDate = value.replace(/[^\d]/g, '');

        // cleanedDateLength is used to know when to apply our formatting for the date
        const cleanedDateLength = cleanedDate.length;

        // we need to return the value with no formatting if its less then five digits
        if (cleanedDateLength < 5) return cleanedDate;

        // if cleanedDateLength is greater than 5 and less the 7 we start to return
        // the formatted date
        if (cleanedDateLength < 7) {
          return `${cleanedDate.slice(0, 4)}${formatString}${cleanedDate.slice(
            4
          )}`;
        }

        // finally, if the cleanedDateLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `${cleanedDate.slice(0, 4)}${formatString}${cleanedDate.slice(
          4,
          6
        )}${formatString}${cleanedDate.slice(6, 8)}`;
      };

      const handleSelectionRange = () => {
        if (typeof selectionPosition === 'number' && selectionPosition < 10) {
          if (
            (event.target.value !== value &&
              selectionPosition === 5 &&
              event.target.value.length === 6) ||
            (event.target.value !== value &&
              selectionPosition === 8 &&
              event.target.value.length === 9)
          ) {
            return event.target.setSelectionRange(
              selectionPosition + 1,
              selectionPosition + 1
            );
          } else if (
            (event.target.value === value &&
              selectionPosition === 5 &&
              event.target.value.length === 6) ||
            (event.target.value === value &&
              selectionPosition === 8 &&
              event.target.value.length === 9)
          ) {
            return event.target.setSelectionRange(
              selectionPosition - 1,
              selectionPosition - 1
            );
          }
          return event.target.setSelectionRange(
            selectionPosition,
            selectionPosition
          );
        }
      };

      return { getFormattedDate, handleSelectionRange };
    }
    default:
      throw new Error('Handle the provided format');
  }
};

export const formatDate = (date: string): string => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const today = (): string => {
  return dayjs().format('YYYY-MM-DD');
};

export const eighteenYearsAgo = (): string => {
  return dayjs().subtract(18, 'year').format('YYYY-MM-DD');
};

export const in15Years = (): string => {
  return dayjs().add(15, 'year').format('YYYY-MM-DD');
};
