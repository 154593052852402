import { dataverseFetch, dataverseFetchWithNotification } from './http';
import { toast } from '../../lib/customToast';
import { apiErrorHandler } from '../apiResultHandlers';
import { getActiveDemandeId } from '../../utils/demande.utils';

export interface FilePayload {
  fileName: string;
  step?: number;
  id: string;
  createdAt?: string;
  demandeId?: string;
}

export const getFilesForDemande = async (demandeId: string) =>
  dataverseFetch<FilePayload[]>(`/demandes/${demandeId}/files`);

export const downloadFile = async (
  demandeId: undefined | string,
  fileId: string
) => {
  if (!demandeId) {
    demandeId = getActiveDemandeId() as string;
  }
  if (!demandeId) {
    return;
  }
  try {
    const file = await dataverseFetch<{
      fileBase64Data: string;
      fileName: string;
    }>(`/demandes/${demandeId}/files/${fileId}`);
    const binaryString = window.atob(file.fileBase64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes.buffer]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = file.fileName;
    a.click();
  } catch (e) {
    apiErrorHandler();
  }
};

export const uploadFile = async (
  demandeId: string,
  step: number,
  file: File
) => {
  const fileData = await file.arrayBuffer();
  return uploadFormBody(
    demandeId,
    step,
    buildFormData(fileData, file.name, file.type || 'application/octet-stream')
  );
};

export const uploadHtmlFile = async (
  demandeId: string,
  step: number,
  fileName: string,
  html: Blob
) => {
  const formBody = buildFormData(html, fileName, 'text/html');
  return uploadFormBody(demandeId, step, formBody);
};

async function uploadFormBody(
  demandeId: string,
  step: number,
  formBody: FormData
) {
  return dataverseFetchWithNotification<FilePayload>(
    `/demandes/${demandeId}/step/${step}/files`,
    {
      method: 'POST',
      body: formBody,
    }
  );
}

function buildFormData(
  formBodyPart: BlobPart,
  fileName: string,
  type: string
): FormData {
  const formBody = new FormData();
  const fileBlob = new Blob([formBodyPart], { type });
  formBody.set('file', fileBlob, fileName);
  return formBody;
}
