import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Fragment } from 'react/jsx-runtime';
import Columns from '../components/containers/Columns';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import Button, { ButtonTypes } from '../forms/Button';
import Form from '../forms/Form';
import RegisteredDateField from '../forms/registeredFields/RegisteredDateField';
import RegisteredRadioGroup from '../forms/registeredFields/RegisteredRadioGroup';
import RegisteredSelect from '../forms/registeredFields/RegisteredSelect';
import RegisteredTextField from '../forms/registeredFields/RegisteredTextField';
import { RequestData } from '../mockData/mockRequestData';
import {
  defaultValues,
  surrogateNoticeSchema,
} from '../utils/surrogateNotice.utils';
import { zodResolver } from '@hookform/resolvers/zod';
import RegisteredCheckboxGroup from '../forms/registeredFields/RegisteredCheckboxGroup';
import { useEffect } from 'react';

interface SurrogateNoticeProps {
  requestData: RequestData;
}

const SurrogateNotice = ({ requestData }: SurrogateNoticeProps) => {
  const { i18n, t } = useTranslation();
  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: zodResolver(surrogateNoticeSchema(i18n.language)),
    shouldUnregister: true,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'infantFields',
    shouldUnregister: true,
  });

  const submitFn = (data: any) => {
    console.log(data);
  };

  const instructions: { heading: string; text: string }[] = t(
    'surrogateNotice.instructions.items',
    {
      returnObjects: true,
    }
  );

  const numberOfChildren = watch('numberOfChildren');

  useEffect(() => {
    if (fields.length < numberOfChildren) {
      append({ lastName: '', firstName: '', sex: null, dob: '' });
    } else if (fields.length > watch('numberOfChildren')) {
      remove(fields.length - 1);
    }
  }, [append, fields.length, numberOfChildren, remove, watch]);

  return (
    <ScreenContainer>
      <SectionContainer>
        <h1 className='heading1'>{t('surrogateNotice.heading')}</h1>
        {/*<RequestDetails requestData={requestData} />*/}
      </SectionContainer>
      <SectionContainer>
        <h2 className='heading2'>
          {t('surrogateNotice.instructions.heading')}
        </h2>

        <p className='!mb-5'>{t('surrogateNotice.instructions.paragraph')}</p>

        {instructions.map((instruction, i) => (
          <Fragment key={i}>
            <ReactMarkdown className='whitespace-pre-wrap'>
              {instruction.heading}
            </ReactMarkdown>
            <ReactMarkdown className='!mb-5 ml-5 whitespace-pre-wrap'>
              {instruction.text}
            </ReactMarkdown>
          </Fragment>
        ))}
        <ReactMarkdown className='whitespace-pre-wrap'>
          {t('surrogateNotice.instructions.notes')}
        </ReactMarkdown>
      </SectionContainer>
      <Form onSubmit={handleSubmit(submitFn)}>
        <SectionContainer>
          <h2 className='heading2'>
            {t('surrogateNotice.eventToCommunicate.heading')}
          </h2>
          <RegisteredRadioGroup
            required
            name={'event'}
            register={register}
            options={[
              {
                value: 'surrogatePregnant',
                label: t(
                  'surrogateNotice.eventToCommunicate.options.surrogatePregnant'
                ),
              },
              {
                value: 'terminatedPregnancy',
                label: t(
                  'surrogateNotice.eventToCommunicate.options.terminatedPregnancy'
                ),
              },
              {
                value: 'birth',
                label: t('surrogateNotice.eventToCommunicate.options.birth'),
              },
            ]}
            label={t('surrogateNotice.eventToCommunicate.selectEvent')}
            errorMessage={errors.event?.message}
          />
        </SectionContainer>

        {watch('event') === 'surrogatePregnant' && (
          <SectionContainer>
            <h2 className='heading2'>
              {t('surrogateNotice.surrogatePregnant.heading')}
            </h2>
            <p>
              {requestData.fileType === 'couple'
                ? t('surrogateNotice.surrogatePregnant.paragraphCouple')
                : t('surrogateNotice.surrogatePregnant.paragraphSingle')}
            </p>
            <Columns numOfCols={2} className='!mt-5'>
              <RegisteredDateField
                required
                name={'expectedDeliveryDate'}
                register={register}
                label={t('surrogateNotice.surrogatePregnant.dateInput')}
                errorMessage={errors.expectedDeliveryDate?.message}
              />
              <div />
            </Columns>
            <h3 className='heading3'>
              {t('surrogateNotice.surrogatePregnant.materialUsed.heading')}
            </h3>
            {requestData.fileType === 'couple' ? (
              <RegisteredCheckboxGroup
                label={t(
                  'surrogateNotice.surrogatePregnant.materialUsed.sperm'
                )}
                required
                register={register}
                name={'sperm'}
                options={[
                  {
                    label: t(
                      'surrogateNotice.surrogatePregnant.materialUsed.options.parent',
                      { number: '1' }
                    ),
                    name: 'parent1',
                  },
                  {
                    label: t(
                      'surrogateNotice.surrogatePregnant.materialUsed.options.parent',
                      { number: '2' }
                    ),
                    name: 'parent2',
                  },
                  {
                    label: t(
                      'surrogateNotice.surrogatePregnant.materialUsed.options.spermDonor'
                    ),
                    name: 'donor',
                  },
                ]}
                errorMessage={errors.sperm?.message}
              />
            ) : (
              <RegisteredRadioGroup
                required
                name={'sperm'}
                register={register}
                options={[
                  {
                    value: 'parent',
                    label: t(
                      'surrogateNotice.surrogatePregnant.materialUsed.options.parent',
                      { number: '' }
                    ),
                  },

                  {
                    value: 'donor',
                    label: t(
                      'surrogateNotice.surrogatePregnant.materialUsed.options.spermDonor'
                    ),
                  },
                ]}
                label={t(
                  'surrogateNotice.surrogatePregnant.materialUsed.sperm'
                )}
                errorMessage={errors.sperm?.message}
              />
            )}
            <RegisteredRadioGroup
              required
              name={'egg'}
              register={register}
              options={
                requestData.fileType === 'couple'
                  ? [
                      {
                        value: 'parent1',
                        label: t(
                          'surrogateNotice.surrogatePregnant.materialUsed.options.parent',
                          { number: '1' }
                        ),
                      },
                      {
                        value: 'parent2',
                        label: t(
                          'surrogateNotice.surrogatePregnant.materialUsed.options.parent',
                          { number: '2' }
                        ),
                      },
                      {
                        value: 'surrogate',
                        label: t(
                          'surrogateNotice.surrogatePregnant.materialUsed.options.surrogate'
                        ),
                      },
                      {
                        value: 'eggDonor',
                        label: t(
                          'surrogateNotice.surrogatePregnant.materialUsed.options.eggDonor'
                        ),
                      },
                    ]
                  : [
                      {
                        value: 'parent',
                        label: t(
                          'surrogateNotice.surrogatePregnant.materialUsed.options.parent',
                          { number: '' }
                        ),
                      },
                      {
                        value: 'surrogate',
                        label: t(
                          'surrogateNotice.surrogatePregnant.materialUsed.options.surrogate'
                        ),
                      },
                      {
                        value: 'eggDonor',
                        label: t(
                          'surrogateNotice.surrogatePregnant.materialUsed.options.eggDonor'
                        ),
                      },
                    ]
              }
              label={t('surrogateNotice.surrogatePregnant.materialUsed.egg')}
              errorMessage={errors.egg?.message}
            />
            <p>
              {requestData.fileType === 'couple'
                ? t('surrogateNotice.surrogatePregnant.materialUsed.noteCouple')
                : t(
                    'surrogateNotice.surrogatePregnant.materialUsed.noteSingle'
                  )}
            </p>
          </SectionContainer>
        )}

        {watch('event') === 'terminatedPregnancy' && (
          <SectionContainer>
            <h2 className='heading2'>
              {t('surrogateNotice.terminatedPregnancy.heading')}
            </h2>
            <ReactMarkdown className='!mb-5 whitespace-pre-wrap'>
              {requestData.fileType === 'couple'
                ? t('surrogateNotice.terminatedPregnancy.paragraphCouple')
                : t('surrogateNotice.terminatedPregnancy.paragraphSingle')}
            </ReactMarkdown>
            <Columns numOfCols={2}>
              <RegisteredDateField
                required
                name={'terminationDate'}
                register={register}
                label={t('surrogateNotice.terminatedPregnancy.dateField')}
                errorMessage={errors.terminationDate?.message}
              />
              <div />
            </Columns>
            <ReactMarkdown>
              {t('surrogateNotice.terminatedPregnancy.reminder')}
            </ReactMarkdown>
          </SectionContainer>
        )}

        {watch('event') === 'birth' && (
          <SectionContainer>
            <h2 className='heading2'>{t('surrogateNotice.birth.heading')}</h2>
            <p className='!mb-5'>
              {requestData.fileType === 'couple'
                ? t('surrogateNotice.birth.paragraphCouple')
                : t('surrogateNotice.birth.paragraphSingle')}
            </p>
            <RegisteredSelect
              required
              size='sm'
              name={'numberOfChildren'}
              register={register}
              label={t('surrogateNotice.birth.infantId.infantNumberSelect')}
              options={[
                ...Array.from({ length: 10 }, (_value, index) => index),
              ].map((e) => ({
                label: e + 1,
                value: e + 1,
              }))}
              defaultValue={1}
              placeholder={t('common.forms.selectPlaceholder')}
              errorMessage={errors.numberOfChildren?.message}
            />

            {fields.map((field, i) => (
              <div key={field.id}>
                <h3 className='heading3'>
                  {t('surrogateNotice.birth.infantId.heading', {
                    number: numberOfChildren > 1 ? i + 1 : '',
                  })}
                </h3>
                <Columns numOfCols={2}>
                  <RegisteredTextField
                    required
                    name={`infantFields.${i}.lastName` as const}
                    register={register}
                    label={t('surrogateNotice.birth.infantId.fields.lastName')}
                    errorMessage={errors.infantFields?.[i]?.lastName?.message}
                  />
                  <RegisteredTextField
                    required
                    name={`infantFields.${i}.firstName` as const}
                    register={register}
                    label={t('surrogateNotice.birth.infantId.fields.firstName')}
                    errorMessage={errors.infantFields?.[i]?.firstName?.message}
                  />
                </Columns>
                <Columns numOfCols={2}>
                  <RegisteredSelect
                    required
                    name={`infantFields.${i}.sex` as const}
                    register={register}
                    label={t('surrogateNotice.birth.infantId.fields.sex')}
                    options={[
                      { value: 'female', label: 'Female' },
                      { value: 'male', label: 'Male' },
                      { value: 'other', label: 'Other' },
                    ]}
                    placeholder={t('common.forms.selectPlaceholder')}
                    errorMessage={errors.infantFields?.[i]?.sex?.message}
                  />
                  <RegisteredDateField
                    required
                    name={`infantFields.${i}.dob` as const}
                    register={register}
                    label={t('surrogateNotice.birth.infantId.fields.dob')}
                    errorMessage={errors.infantFields?.[i]?.dob?.message}
                  />
                </Columns>
              </div>
            ))}

            <h3 className='heading3'>
              {t('surrogateNotice.birth.placeOfBirth.heading')}
            </h3>
            <Columns numOfCols={2}>
              <div>
                <RegisteredTextField
                  required
                  name='municipality'
                  register={register}
                  label={t('surrogateNotice.birth.placeOfBirth.municipality')}
                  errorMessage={errors.municipality?.message}
                />
                <RegisteredDateField
                  required
                  name={`expectedReturnToQuebec`}
                  register={register}
                  label={t('surrogateNotice.birth.placeOfBirth.returnDate')}
                  errorMessage={errors.expectedReturnToQuebec?.message}
                />
              </div>
              <RegisteredTextField
                required
                name='state'
                register={register}
                label={t('surrogateNotice.birth.placeOfBirth.state')}
                errorMessage={errors.state?.message}
              />
            </Columns>
            <ReactMarkdown className='whitespace-pre-wrap'>
              {t('surrogateNotice.birth.note')}
            </ReactMarkdown>
          </SectionContainer>
        )}

        {watch().event !== '' && (
          <SectionContainer>
            <h2 className='!mt-20 !mb-0 heading2'>
              {t('surrogateNotice.signature.heading')}
            </h2>
            <Columns numOfCols={2} className='gap-24'>
              <div>
                <h3 className='heading3'>
                  {t('surrogateNotice.signature.parent', {
                    number: requestData.fileType === 'couple' ? 1 : '',
                  })}
                </h3>
                <Button
                  type={ButtonTypes.submit}
                  className='!mt-8'
                  primary
                  label={t('surrogateNotice.signature.button')}
                />
              </div>
              <div>
                {requestData.parent2 && (
                  <>
                    <h3 className='heading3'>
                      {t('surrogateNotice.signature.parent', {
                        number: '2',
                      })}
                    </h3>
                    <Button
                      type={ButtonTypes.submit}
                      className='!mt-8'
                      primary
                      label={t('surrogateNotice.signature.button')}
                    />
                  </>
                )}
              </div>
            </Columns>
          </SectionContainer>
        )}
      </Form>
    </ScreenContainer>
  );
};

export default SurrogateNotice;
