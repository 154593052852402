import { useApi } from '../../api/APIContext';
import { useEffect, useState } from 'react';
import { DemandeGPAClientPayload } from '../../api/methods/demandes';

export const useActiveDemande = () => {
  const api = useApi();
  const [demande, setDemande] = useState<DemandeGPAClientPayload>();

  useEffect(() => {
    api.demandes.getActivedemande().then((demande) => {
      setDemande(demande);
    });
  }, [api.demandes]);

  return demande;
};
