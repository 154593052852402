import { LegacyRef } from 'react';
import classNames from '../utils/classList.utils';
import { Size, sizeToWidth } from '../utils/fieldSize.utils';

export interface DateFieldProps {
  /**
   * HTML name attribute of the field
   */
  name: string;
  /**
   * Field width
   */
  size?: Size;
  /**
   * Is the field required?
   */
  required?: boolean;
  /**
   * Sub-label details paragraph
   */
  details?: string;
  /**
   * Human readable label
   */
  label: string;
  /**
   * Format for date (supports date-fns formats only)
   */
  errorMessage?: string;
  hidden?: boolean;

  max?: string | number;
  min?: string | number;

  datePickerRef?: LegacyRef<HTMLInputElement>;
}

const DateField = ({
  datePickerRef,
  size = 'full',
  required,
  label,
  hidden,
  details,
  errorMessage,
  max,
  min,
  ...props
}: DateFieldProps) => {
  const { name } = props;
  return (
    <div className={classNames('!mb-8', hidden ? 'hidden' : '')}>
      <label
        htmlFor={name}
        className={classNames('block font-bold', details ? '!mb-0' : '!mb-2')}
      >
        <span>
          {label}
          {required && <span className='font-bold text-red-300'>&nbsp;*</span>}
        </span>
      </label>
      {details && <p className='!mb-1 text-sm'>{details}</p>}
      <input
        type='date'
        {...props}
        ref={datePickerRef}
        id={name}
        max={max}
        min={min}
        className={classNames(
          sizeToWidth(size),
          'paragraph appearance-none border !w-full !py-2 !px-3 leading-tight focus:border-gray-500 focus:border-2 focus:outline-offset-2 focus:outline-2 focus:outline-blue-100 !h-10',
          errorMessage ? 'border-red-300 border-2' : 'border-gray-300'
        )}
      />
      {errorMessage && (
        <p className='!mt-1 text-sm font-bold text-red-300'>{errorMessage}</p>
      )}
    </div>
  );
};

export default DateField;
