import { GpaAPI, useApi } from '../../api/APIContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ChoiceMap<K extends string> = Record<K, ChoiceList[]>;
type SelectOptionsGetter<K extends string> = (
  api: GpaAPI
) => Promise<ChoiceMap<K>>;

export const useFetchedSelectOptions = <K extends string>(
  getter: SelectOptionsGetter<K> // Needs to be a stable reference
) => {
  const api = useApi();
  const { i18n } = useTranslation();

  const [choiceList, setChoiceList] = useState<
    Record<K, ChoiceList[]> | undefined
  >();

  useEffect(() => {
    if (!choiceList) {
      getter(api).then((options) => {
        setChoiceList(options);
      });
    }
  }, [api, getter, choiceList]);

  return buildSelectOptionsMap(choiceList, i18n.language);
};

function buildSelectOptionsMap<K extends string>(
  choiceMap: ChoiceMap<K> | undefined,
  language?: string
) {
  return choiceMap
    ? (key: K) => {
        const choiceList = choiceMap[key];
        return choiceTypeToSelectOptions(choiceList, language);
      }
    : () => [];
}

export function choiceTypeToSelectOptions(
  choiceTypes?: ChoiceList[],
  language?: string
) {
  return (
    choiceTypes?.map((type) => ({
      // Values should be strings since zod enums are strings.
      // Parsing as integer needs to be done on API side
      value: `${type.id}`,
      label: language === 'en' ? type.label_en : type.label_fr,
    })) || []
  );
}
