import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { GpaAPI, useApi } from '../api/APIContext';
import { savePriorAuth } from '../api/methods/demandes';
import BackButton from '../components/BackButton';
import LinkList from '../components/LinkList';
import Pill from '../components/Pill';
import RequestDetails from '../components/RequestDetails';
import Accordion from '../components/containers/Accordion';
import Columns from '../components/containers/Columns';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import Button, { ButtonTypes, PromiseButton } from '../forms/Button';
import Form from '../forms/Form';
import RegisteredCheckbox from '../forms/registeredFields/RegisteredCheckbox';
import RegisteredDateField from '../forms/registeredFields/RegisteredDateField';
import RegisteredRadioGroup from '../forms/registeredFields/RegisteredRadioGroup';
import RegisteredSelect from '../forms/registeredFields/RegisteredSelect';
import RegisteredTextField from '../forms/registeredFields/RegisteredTextField';
import { getFormattedDateTime } from '../lib/dateformat';
import { consentSelection } from '../mockData/priorAuthFormMockData';
import {
  generatePriorAuthSchema,
  getMarriageScenario,
  isDocumentedMarriageType,
} from '../utils/priorAuthForm.utils';
import { SignatureStatus } from '../utils/types';
import {
  determineSignatureState,
  getDemandeParents,
  uploadFormHtml,
  uploadPDFForm,
  waitSeconds,
} from '../utils/authFormCommon.utils';
import OtherParentSignatureSection from '../forms/OtherParentSignatureSection';
import { useActiveDemande } from '../forms/hooks/useActiveDemande';
import { useFetchedSelectOptions } from '../forms/hooks/useFetchedSelectOptions';
import CustomMarkdown from '../components/CustomMarkdown';
import SignatureStateSection from '../forms/SignatureStateSection';
import { scrollToTop } from '../utils/navigation.utils';
import { Alert } from '../alerts/Alert';
import { in15Years, today } from '../utils/date.utils';

const priorAuthFormTypesGetter = (api: GpaAPI) =>
  api.demandes.getPriorAuthFormTypes();

const PriorAuth = () => {
  const { t, i18n } = useTranslation();

  const api = useApi();
  const demande = useActiveDemande();

  const getSelectOptions = useFetchedSelectOptions(priorAuthFormTypesGetter);

  const {
    handleSubmit,
    register,
    unregister,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isLoading },
  } = useForm({
    resolver: zodResolver(generatePriorAuthSchema(i18n.language)),
    defaultValues: async () => api.demandes.getPriorauthForDemande(),
  });

  const marriageType = watch('marriageType');
  const [signingStatus, setSigningStatus] = useState<SignatureStatus>();

  const { selfParent, otherParent, firstParent, secondParent } =
    getDemandeParents(demande);

  const signatureState = determineSignatureState(demande, signingStatus);
  const {
    selfParentHasSigned,
    otherParentHasSigned,
    someoneHasSigned,
    signaturesRequiredCount,
  } = signatureState;

  useEffect(() => {
    if (otherParent) {
      if (!isDocumentedMarriageType(marriageType)) {
        unregister('marriageDocument');
      }
      setValue('scenario', getMarriageScenario(marriageType));
    }
  }, [marriageType, otherParent, setValue, unregister]);

  const links: { label: string; href: string }[] = t('priorAuth.links.items', {
    returnObjects: true,
  });

  useEffect(() => {
    api.demandes
      .getPriorAuthSigningStatus()
      .then((signingStatus) => setSigningStatus(signingStatus));
  }, [api.demandes]);

  useEffect(() => {
    console.log(errors, getValues());
  }, [errors]);

  const sign = async (data: any) => {
    // console.log(data);
    if (demande) {
      const signedPriorAuth = await api.demandes.signPriorAuth(getValues());
      const signatureStateAfterSigning = determineSignatureState(
        demande,
        signedPriorAuth
      );

      setSigningStatus(signedPriorAuth);
      if (signatureStateAfterSigning.isSigningComplete) {
        await waitSeconds(0.1);
        await uploadPDFForm(
          '.exportable-form',
          api,
          demande,
          t('priorAuth.title'),
          3
        );
        window.location.href = `/signed?demandeId=${demande.id}`;
      }
    }
  };

  return (
    <ScreenContainer>
      <Form onSubmit={handleSubmit(sign)}>
        <div className='sticky top-0 z-10 flex justify-between !px-4 !py-2 -!mx-4 bg-truewhite border-b border-gray-200 sm:-!mx-6 lg:-!mx-8 sm:!px-6 lg:!px-8'>
          <BackButton />
          {!someoneHasSigned && (
            <PromiseButton
              label={t('common.forms.save')}
              type={ButtonTypes.button}
              onClick={() => savePriorAuth(getValues())}
              primary
              compact
            />
          )}
        </div>

        <div className='pb-4 exportable-form'>
          <SignatureStateSection signatureState={signatureState} />

          <SectionContainer>
            <h1 className='heading1'>{t('priorAuth.title')}</h1>
            <RequestDetails requestData={demande} />
          </SectionContainer>

          <SectionContainer>
            <LinkList title={t('priorAuth.links.title')} links={links} />
          </SectionContainer>
          <SectionContainer>
            <h2 id='instructions' className='heading2'>
              {t('priorAuth.instructions.title')}
            </h2>
            <CustomMarkdown
              content={t('priorAuth.instructions.paragraph')}
              className='!mb-10'
            />
            <Accordion title={t('priorAuth.instructions.accordion1.title')}>
              <ReactMarkdown>
                {t('priorAuth.instructions.accordion1.text')}
              </ReactMarkdown>
            </Accordion>
            <Accordion title={t('priorAuth.instructions.accordion2.title')}>
              <ReactMarkdown>
                {t('priorAuth.instructions.accordion2.steps')}
              </ReactMarkdown>
              <p className='!mt-5 italic'>
                {t('priorAuth.instructions.accordion2.italicText')}
              </p>
            </Accordion>
            <Accordion title={t('priorAuth.instructions.accordion3.title')}>
              <ReactMarkdown>
                {t('priorAuth.instructions.accordion3.text')}
              </ReactMarkdown>
            </Accordion>
            <ReactMarkdown className='!mt-10 !mb-4 whitespace-pre-wrap'>
              {t('priorAuth.instructions.finalText')}
            </ReactMarkdown>
          </SectionContainer>
          <SectionContainer>
            <h2 className='heading2'>{t('priorAuth.regulation.title')}</h2>
            <CustomMarkdown
              className='!mt-4'
              content={t('priorAuth.regulation.paragraph')}
            />
            <ReactMarkdown className='!my-4'>
              {t('priorAuth.regulation.list')}
            </ReactMarkdown>
          </SectionContainer>
          <SectionContainer>
            <h2 id='userId' className='heading2'>
              {t('priorAuth.form.parents.title')}
            </h2>
            <p>{t('priorAuth.form.parents.description')}</p>
            <fieldset disabled={isLoading || someoneHasSigned}>
              <Columns numOfCols={2}>
                <div>
                  <h3 className='!mb-6 heading3'>
                    {t('priorAuth.parentOfIntent', {
                      number: otherParent ? '1' : '',
                    })}
                  </h3>
                  <Columns numOfCols={2}>
                    <RegisteredTextField
                      name={'parent1.lastName'}
                      register={register}
                      label={t('priorAuth.form.parents.familyName')}
                      defaultValue={firstParent?.lastName ?? ''}
                      readonly
                    />
                    <RegisteredTextField
                      name={'parent1.firstName'}
                      register={register}
                      label={t('priorAuth.form.parents.firstName')}
                      defaultValue={firstParent?.firstName ?? ''}
                      readonly
                    />
                  </Columns>
                  <RegisteredSelect
                    name={'parent1.residence'}
                    register={register}
                    label={t('priorAuth.form.parents.select.label', {
                      name: firstParent?.firstName,
                    })}
                    placeholder={t('priorAuth.form.parents.select.placeholder')}
                    options={getSelectOptions('statutResidenceAuCanada')}
                    errorMessage={errors?.parent1?.residence?.message?.toString()}
                    required
                  />
                </div>
                <div>
                  {secondParent && (
                    <>
                      <h3 className='!mb-6 heading3'>
                        {t('priorAuth.parentOfIntent', { number: 2 })}
                      </h3>
                      <Columns numOfCols={2}>
                        <RegisteredTextField
                          name={'parent2.lastName'}
                          register={register}
                          label={t('priorAuth.form.parents.familyName')}
                          defaultValue={secondParent?.lastName ?? ''}
                          readonly
                          errorMessage={errors?.parent2?.lastName?.message?.toString()}
                        />
                        <RegisteredTextField
                          name={'parent2.firstName'}
                          register={register}
                          label={t('priorAuth.form.parents.firstName')}
                          defaultValue={secondParent?.firstName ?? ''}
                          readonly
                          errorMessage={errors?.parent2?.firstName?.message?.toString()}
                        />
                      </Columns>
                      <RegisteredSelect
                        name={'parent2.residence'}
                        register={register}
                        label={t('priorAuth.form.parents.select.label', {
                          name: secondParent?.firstName,
                        })}
                        options={getSelectOptions('statutResidenceAuCanada')}
                        placeholder={t('priorAuth.form.parents.select.citizen')}
                        errorMessage={errors?.parent2?.residence?.message?.toString()}
                        required
                      />
                    </>
                  )}
                </div>
              </Columns>
              <h2 id='state' className='heading2'>
                {t('priorAuth.form.chosenState.title')}
              </h2>
              <p className='!my-5'>
                {t('priorAuth.form.chosenState.subtitle')}
              </p>
              <Columns numOfCols={2} className='md:gap-24'>
                <RegisteredSelect
                  name={'chosenState'}
                  register={register}
                  label={t('priorAuth.form.chosenState.select')}
                  placeholder={t('priorAuth.form.chosenState.placeholder')}
                  options={getSelectOptions('etats')}
                  errorMessage={errors?.chosenState?.message?.toString()}
                  required
                />
              </Columns>
              <h2 id='attachDocs' className='heading2'>
                {t('priorAuth.form.documentsAttach.heading')}
              </h2>
              <p>{t('priorAuth.form.documentsAttach.paragraph')}</p>
              <h3 className='heading3'>
                {t('priorAuth.form.documentsAttach.idProof.heading', {
                  number: 2,
                })}
              </h3>
              <Columns numOfCols={2}>
                <div>
                  {otherParent && (
                    <h4 className='!mb-6 heading4'>
                      {t('priorAuth.parentOfIntent', { number: 1 })}
                    </h4>
                  )}
                  <RegisteredSelect
                    name='parent1.idProof1.docType'
                    register={register}
                    label={t('priorAuth.form.documentsAttach.idProof.label', {
                      number: 1,
                    })}
                    options={getSelectOptions('preuvevalidationpi')}
                    placeholder={t('common.forms.selectPlaceholder')}
                    errorMessage={errors?.parent1?.idProof1?.docType?.message?.toString()}
                    required
                  />
                  <RegisteredDateField
                    name='parent1.idProof1.expiration'
                    min={today()}
                    max={in15Years()}
                    register={register}
                    label={t(
                      'priorAuth.form.documentsAttach.idProof.expiration'
                    )}
                    errorMessage={errors?.parent1?.idProof1?.expiration?.message?.toString()}
                    required
                  />
                  <div className='!mt-16'>
                    <RegisteredSelect
                      name='parent1.idProof2.docType'
                      register={register}
                      label={t('priorAuth.form.documentsAttach.idProof.label', {
                        number: 2,
                      })}
                      options={getSelectOptions('preuvevalidationpi')}
                      placeholder={t('common.forms.selectPlaceholder')}
                      errorMessage={errors?.parent1?.idProof2?.docType?.message?.toString()}
                      required
                    />
                    <RegisteredDateField
                      name='parent1.idProof2.expiration'
                      min={today()}
                      max={in15Years()}
                      register={register}
                      label={t(
                        'priorAuth.form.documentsAttach.idProof.expiration'
                      )}
                      errorMessage={errors?.parent1?.idProof2?.expiration?.message?.toString()}
                      required
                    />
                  </div>
                </div>
                <div>
                  {otherParent && (
                    <>
                      <h4 className='!mb-6 heading4'>
                        {t('priorAuth.parentOfIntent', { number: 2 })}
                      </h4>
                      <RegisteredSelect
                        name={'parent2.idProof1.docType'}
                        register={register}
                        label={t(
                          'priorAuth.form.documentsAttach.idProof.label',
                          {
                            number: 1,
                          }
                        )}
                        options={getSelectOptions('preuvevalidationpi')}
                        placeholder={t('common.forms.selectPlaceholder')}
                        errorMessage={errors?.parent2?.idProof1?.docType?.message?.toString()}
                        required
                      />
                      <RegisteredDateField
                        name={'parent2.idProof1.expiration'}
                        min={today()}
                        max={in15Years()}
                        register={register}
                        label={t(
                          'priorAuth.form.documentsAttach.idProof.expiration'
                        )}
                        errorMessage={errors?.parent2?.idProof1?.expiration?.message?.toString()}
                        required
                      />
                      <div className='!mt-16'>
                        <RegisteredSelect
                          name={'parent2.idProof2.docType'}
                          register={register}
                          label={t(
                            'priorAuth.form.documentsAttach.idProof.label',
                            {
                              number: 2,
                            }
                          )}
                          options={getSelectOptions('preuvevalidationpi')}
                          placeholder={t('common.forms.selectPlaceholder')}
                          errorMessage={errors?.parent2?.idProof2?.docType?.message?.toString()}
                          required
                        />
                        <RegisteredDateField
                          name={'parent2.idProof2.expiration'}
                          min={today()}
                          max={in15Years()}
                          register={register}
                          label={t(
                            'priorAuth.form.documentsAttach.idProof.expiration'
                          )}
                          errorMessage={errors?.parent2?.idProof2?.expiration?.message?.toString()}
                          required
                        />
                      </div>
                    </>
                  )}
                </div>
              </Columns>
              <h3 className='heading3'>
                {t('priorAuth.form.documentsAttach.proofOfDomicile.heading')}
              </h3>
              <Columns numOfCols={2}>
                <div>
                  {otherParent && (
                    <h4 className='!mb-6 heading4'>
                      {t('priorAuth.parentOfIntent', { number: 1 })}
                    </h4>
                  )}
                  <RegisteredSelect
                    name={'parent1.residenceProofType1'}
                    register={register}
                    label={t(
                      'priorAuth.form.documentsAttach.proofOfDomicile.label',
                      {
                        number: 1,
                      }
                    )}
                    options={getSelectOptions('preuvededomicile')}
                    placeholder={t('common.forms.selectPlaceholder')}
                    errorMessage={errors?.parent1?.residenceProofType1?.message?.toString()}
                    required
                  />
                  <RegisteredSelect
                    name={'parent1.residenceProofType2'}
                    register={register}
                    label={t(
                      'priorAuth.form.documentsAttach.proofOfDomicile.label',
                      {
                        number: 2,
                      }
                    )}
                    options={getSelectOptions('preuvededomicile')}
                    placeholder={t('common.forms.selectPlaceholder')}
                    errorMessage={errors?.parent1?.residenceProofType2?.message?.toString()}
                    required
                  />
                </div>
                <div>
                  {otherParent && (
                    <>
                      <h4 className='!mb-6 heading4'>
                        {t('priorAuth.parentOfIntent', { number: 2 })}
                      </h4>
                      <RegisteredSelect
                        name={'parent2.residenceProofType1'}
                        register={register}
                        label={t(
                          'priorAuth.form.documentsAttach.proofOfDomicile.label',
                          {
                            number: 1,
                          }
                        )}
                        options={getSelectOptions('preuvededomicile')}
                        placeholder={t('common.forms.selectPlaceholder')}
                        errorMessage={errors?.parent2?.residenceProofType1?.message?.toString()}
                        required
                      />
                      <RegisteredSelect
                        name={'parent2.residenceProofType2'}
                        register={register}
                        label={t(
                          'priorAuth.form.documentsAttach.proofOfDomicile.label',
                          {
                            number: 2,
                          }
                        )}
                        options={getSelectOptions('preuvededomicile')}
                        placeholder={t('common.forms.selectPlaceholder')}
                        errorMessage={errors?.parent2?.residenceProofType2?.message?.toString()}
                        required
                      />
                    </>
                  )}
                </div>
              </Columns>
              <ReactMarkdown>
                {t('priorAuth.form.documentsAttach.proofOfDomicile.note')}
              </ReactMarkdown>
              <h3 className='heading3'>
                {t(
                  'priorAuth.form.documentsAttach.canadianCitizenship.heading'
                )}
              </h3>
              <Columns numOfCols={2}>
                <div>
                  {otherParent && (
                    <h4 className='!mb-6 heading4'>
                      {t('priorAuth.parentOfIntent', { number: 1 })}
                    </h4>
                  )}
                  <RegisteredSelect
                    name={'parent1.citizenshipProof.docType'}
                    register={register}
                    label={t(
                      'priorAuth.form.documentsAttach.canadianCitizenship.proofType.ifProof'
                    )}
                    options={getSelectOptions('preuveCit')}
                    placeholder={t('common.forms.selectPlaceholder')}
                    errorMessage={errors?.parent1?.citizenshipProof?.docType?.message?.toString()}
                    required
                  />
                  <RegisteredDateField
                    name={'parent1.citizenshipProof.expiration'}
                    min={today()}
                    max={in15Years()}
                    register={register}
                    label={t(
                      'priorAuth.form.documentsAttach.canadianCitizenship.proofType.expiration'
                    )}
                    errorMessage={errors?.parent1?.citizenshipProof?.expiration?.message?.toString()}
                  />
                </div>
                {otherParent ? (
                  <div>
                    <h4 className='!mb-6 heading4'>
                      {t('priorAuth.parentOfIntent', { number: 2 })}
                    </h4>
                    <RegisteredSelect
                      name={'parent2.citizenshipProof.docType'}
                      register={register}
                      label={t(
                        'priorAuth.form.documentsAttach.canadianCitizenship.proofType.ifProof'
                      )}
                      options={getSelectOptions('preuveCit')}
                      placeholder={t('common.forms.selectPlaceholder')}
                      errorMessage={errors?.parent2?.citizenshipProof?.docType?.message?.toString()}
                      required
                    />
                    <RegisteredDateField
                      name={'parent2.citizenshipProof.expiration'}
                      min={today()}
                      max={in15Years()}
                      register={register}
                      label={t(
                        'priorAuth.form.documentsAttach.canadianCitizenship.proofType.expiration'
                      )}
                      errorMessage={errors?.parent2?.citizenshipProof?.expiration?.message?.toString()}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </Columns>

              <h3 className='!w-10/12 !mb-5 heading3'>
                {t('priorAuth.form.documentsAttach.certification.heading')}
              </h3>
              <Columns numOfCols={2}>
                <div>
                  {otherParent && (
                    <h4 className='!mb-6 heading4'>
                      {t('priorAuth.parentOfIntent', { number: 1 })}
                    </h4>
                  )}
                  <RegisteredDateField
                    name={'parent1.certOfAttendanceDate'}
                    max={today()}
                    register={register}
                    label={t(
                      'priorAuth.form.documentsAttach.certification.date'
                    )}
                    errorMessage={errors?.parent1?.certOfAttendanceDate?.message?.toString()}
                    required
                  />
                </div>
                <div>
                  {otherParent && (
                    <>
                      <h4 className='!mb-6 heading4'>
                        {t('priorAuth.parentOfIntent', { number: 2 })}
                      </h4>
                      <RegisteredDateField
                        name={'parent2.certOfAttendanceDate'}
                        max={today()}
                        register={register}
                        label={t(
                          'priorAuth.form.documentsAttach.certification.date'
                        )}
                        errorMessage={errors?.parent2?.certOfAttendanceDate?.message?.toString()}
                        required
                      />
                    </>
                  )}
                </div>
              </Columns>

              <h3 className='!mb-5 heading3'>
                {t('priorAuth.form.documentsAttach.criminalRecord.heading')}
              </h3>
              <Columns numOfCols={2}>
                <div>
                  {otherParent && (
                    <h4 className='!mb-6 heading4'>
                      {t('priorAuth.parentOfIntent', { number: 1 })}
                    </h4>
                  )}
                  <RegisteredDateField
                    name={'parent1.criminalRecordCheckDate'}
                    max={today()}
                    register={register}
                    label={t(
                      'priorAuth.form.documentsAttach.criminalRecord.date'
                    )}
                    errorMessage={errors?.parent1?.criminalRecordCheckDate?.message?.toString()}
                    required
                  />
                </div>
                <div>
                  {otherParent && (
                    <>
                      <h4 className='!mb-6 heading4'>
                        {t('priorAuth.parentOfIntent', { number: 2 })}
                      </h4>
                      <RegisteredDateField
                        name={'parent2.criminalRecordCheckDate'}
                        max={today()}
                        register={register}
                        label={t(
                          'priorAuth.form.documentsAttach.criminalRecord.date'
                        )}
                        errorMessage={errors?.parent2?.criminalRecordCheckDate?.message?.toString()}
                        required
                      />
                    </>
                  )}
                </div>
              </Columns>
              <ReactMarkdown>
                {t('priorAuth.form.documentsAttach.criminalRecord.note')}
              </ReactMarkdown>
              {!otherParent && (
                <>
                  <h2 className='heading2' id='maritalStatus'>
                    {t('priorAuth.form.maritalStatus.title')}
                  </h2>
                  <Alert
                    message={t('priorAuth.form.maritalStatus.singleParent')}
                    button={
                      <Button
                        compact
                        className={'!mt-4'}
                        onClick={() => {
                          savePriorAuth(getValues());
                          window.location.href = `/personalInfo?demandeId=${demande?.id}`;
                        }}
                        label={t('priorAuth.form.maritalStatus.manageParents')}
                      />
                    }
                  />
                </>
              )}
              {otherParent && (
                <>
                  <h2 className='heading2' id='maritalStatus'>
                    {t('priorAuth.form.maritalStatus.title')}
                  </h2>
                  <p className='!my-5'>
                    {t('priorAuth.form.maritalStatus.subtitle')}
                  </p>
                  <>
                    <RegisteredRadioGroup
                      name={'marriageType'}
                      register={register}
                      options={getSelectOptions('statutconjugal')}
                      label={t('priorAuth.form.maritalStatus.unionType.title')}
                      errorMessage={errors?.marriageType?.message?.toString()}
                      required
                    />
                    {isDocumentedMarriageType(marriageType) && (
                      <RegisteredSelect
                        name={'marriageDocument'}
                        register={register}
                        label={t('priorAuth.form.maritalStatus.document.title')}
                        options={getSelectOptions('preuveunionconjugale')}
                        placeholder={t('common.forms.selectPlaceholder')}
                        errorMessage={errors?.marriageDocument?.message?.toString()}
                        required
                      />
                    )}
                  </>
                </>
              )}
              <h2 className='heading2' id='declaration'>
                {t('priorAuth.form.declaration.title')}
              </h2>
              <Columns numOfCols={2} className='md:gap-24'>
                <div>
                  <RegisteredDateField
                    name={'dateOfDeclaration'}
                    max={today()}
                    register={register}
                    label={t('priorAuth.form.declaration.date')}
                    errorMessage={errors?.dateOfDeclaration?.message?.toString()}
                    required
                  />
                  <RegisteredTextField
                    name={'declarationNumber'}
                    register={register}
                    label={t('priorAuth.form.declaration.number')}
                    errorMessage={errors?.declarationNumber?.message?.toString()}
                    required
                  />
                </div>
              </Columns>
              <h2 className='heading2' id='consent'>
                {t('priorAuth.form.consent.title')}
              </h2>
              <ReactMarkdown className='!my-5 whitespace-pre-wrap'>
                {t('priorAuth.form.consent.paragraph')}
              </ReactMarkdown>
              <Columns numOfCols={2} className='md:gap-24'>
                <div>
                  {otherParent && (
                    <h3 className='heading3'>
                      {t('priorAuth.parentOfIntent', {
                        number: !demande?.isCouple ? '' : 1,
                      })}
                    </h3>
                  )}
                  <RegisteredRadioGroup
                    name={'parent1.consent'}
                    register={register}
                    options={consentSelection}
                    label={t('priorAuth.form.consent.parent.label')}
                    errorMessage={errors?.parent1?.consent?.message?.toString()}
                    required
                  />
                </div>
                <div>
                  {otherParent && (
                    <>
                      <h3 className='heading3'>
                        {t('priorAuth.parentOfIntent', { number: 2 })}
                      </h3>
                      <RegisteredRadioGroup
                        name={'parent2.consent'}
                        register={register}
                        options={consentSelection}
                        label={t('priorAuth.form.consent.parent.label')}
                        errorMessage={errors?.parent2?.consent?.message?.toString()}
                        required
                      />
                    </>
                  )}
                </div>
              </Columns>
            </fieldset>
            <h3 className='!mt-20 heading3'>
              {t('changeRequest.signatureForm')}
            </h3>

            <Columns numOfCols={2}>
              <div className='flex flex-col'>
                <h4 className={'heading4 !mb-2 space-x-4'}>
                  <span>
                    {selfParent?.firstName} {selfParent?.lastName}
                  </span>
                  {selfParentHasSigned ? (
                    <Pill
                      type={'success'}
                      text={t('common.signature.signedLabel')}
                    />
                  ) : (
                    <Pill
                      type={'warning'}
                      text={t('common.signature.toSignLabel')}
                    />
                  )}
                </h4>

                {!selfParentHasSigned ? (
                  <>
                    <RegisteredCheckbox
                      name='signConsent'
                      label={t('common.signature.clickToSign')}
                      register={register}
                    />
                    <Button
                      type={ButtonTypes.submit}
                      disabled={isSubmitting || !watch('signConsent')}
                      loading={isSubmitting}
                      primary
                      label={t('changeRequest.button')}
                      className='inline mr-auto'
                    />
                  </>
                ) : (
                  <>
                    <p className={'!mb-8'}>
                      {t('common.signature.signed', {
                        name: `${selfParent?.firstName} ${selfParent?.lastName}`,
                        time: getFormattedDateTime(
                          selfParentHasSigned,
                          i18n.language
                        ),
                      })}
                    </p>
                  </>
                )}
              </div>
              <OtherParentSignatureSection
                otherParent={otherParent}
                otherParentSignatureDate={otherParentHasSigned}
              />
            </Columns>
          </SectionContainer>
        </div>
      </Form>
    </ScreenContainer>
  );
};

export default PriorAuth;
