import * as environment from './environment';
import * as demandesMethods from './methods/demandes';
import * as infoGPAMethods from './methods/infoGPA';
import * as filesMethods from './methods/files';
import * as messagesMethods from './methods/messages';
import { dataverseFetch } from './methods/http';

export const init = environment.init;

/*
    This function will redirect the user to the OAuth login page
 */
export const forceLogin = () => {
  const { oAuthUrl, oauthClientId } = environment.gpahqEnv();
  window.location.href = `${oAuthUrl || process.env.REACT_APP_GPA_HQ_OAUTH_URL}?kc_idp_hint=sqin&redirect_uri=${window.location.origin}&client_id=${oauthClientId || process.env.REACT_APP_GPA_HQ_OAUTH_CLIENT_ID}&response_type=token&scope=openid`;
};

export const renewToken = () => {
  const { catchJwtFromBody } = environment;

  dataverseFetch<{ jwt: string }>('/pushJwt').then(catchJwtFromBody);
  fetch('/KeepAlive').then().catch();
};

export const protectRoute = async (): Promise<string | undefined> => {
  const { gpahqEnv, getJwt, catchJwtFromBody } = environment;
  const { azFunctionsUrl } = gpahqEnv();
  const jwt = getJwt();

  if (jwt) {
    return jwt;
  }

  const url = new URL(window.location.href.replace('#', '?'));
  const queryParams = url.searchParams;
  const access_token = queryParams.get('access_token');

  if (access_token) {
    const response = await fetch(
      `${azFunctionsUrl || process.env.REACT_APP_GPA_HQ_AZ_FUNCTIONS}/jwtId`,
      {
        headers: {
          Authorization: 'Bearer ' + access_token,
        },
      }
    );
    if (response.ok) {
      const body = await response.json();
      catchJwtFromBody(body);
      if (body.hasDemandes) {
        return (window.location.href = '/portal');
      } else {
        return (window.location.href = '/');
      }
    }
  } else {
    forceLogin();
  }
};

export const isAuthenticated = () => {
  return environment.getJwt() !== undefined;
};

export const demandes = demandesMethods;
export const infoGPA = infoGPAMethods;
export const files = filesMethods;
export const messages = messagesMethods;
