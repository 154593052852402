import { useTranslation } from 'react-i18next';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { ArrowLongLeftIcon } from '@heroicons/react/24/outline';
import classNames from '../utils/classList.utils';
import { useEffect, useRef } from 'react';

interface StepPaginationProps {
  step: number;
  setStep: (step: number) => void;
  steps: string[];
  anchor?: string;
}

const StepPagination = ({
  step,
  setStep,
  steps,
  anchor,
}: StepPaginationProps) => {
  const { t } = useTranslation();
  const firstRender = useRef(true);

  const scrollToId = (id: string) => {
    const shadowRoot = document.querySelector('gpa-hq-app')?.shadowRoot;
    if (!shadowRoot) {
      return;
    }
    const element = shadowRoot.getElementById(id);
    element && element.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (anchor) {
      scrollToId(anchor);
    }
  }, [step, anchor]);

  return (
    <div
      className={classNames(
        'flex !mt-24',
        step > 1 ? 'justify-between' : 'justify-end'
      )}
    >
      {step > 1 && (
        <button
          onClick={() => setStep(step - 1)}
          className='!px-8 !py-6 paragraph text-left flex bg-truewhite hover:bg-blue-100 text-blue-400 hover:bg-truewhite-200 disabled:cursor-not-allowed font-bold border-blue-400 transition-all  flex-row items-center gap-3 justify-center border-2'
        >
          <ArrowLongLeftIcon className='!w-6' />
          <div>
            <span className='font-bold'>
              {t('common.viewStep', { step: step - 1 })}
            </span>
            <span className='block'>{steps[step - 2] && steps[step - 2]}</span>
          </div>
        </button>
      )}
      {step < steps.length && (
        <button
          onClick={() => setStep(step + 1)}
          className='!px-8 !py-6 paragraph text-right flex bg-truewhite hover:bg-blue-100 text-blue-400 hover:bg-truewhite-200 disabled:cursor-not-allowed font-bold border-blue-400 transition-all  flex-row items-center gap-3 justify-center border-2'
        >
          <div>
            <span className='font-bold'>
              {t('common.viewStep', { step: step + 1 })}
            </span>
            <span className='block'>{steps[step] && steps[step]}</span>
          </div>
          <ArrowLongRightIcon className='!w-6' />
        </button>
      )}
    </div>
  );
};

export default StepPagination;
