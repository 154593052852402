import { z } from 'zod';
import { translateLanguage } from './translateLanguage.utils';

export const requiredFieldMessage = (locale: string) => {
  return translateLanguage('common.forms.required', locale);
};
export const errorMessageDefinition = (locale: string) => {
  return {
    message: requiredFieldMessage(locale),
  };
};
export const requiredString = (locale: string) =>
  z
    .string(errorMessageDefinition(locale))
    .min(1, errorMessageDefinition(locale));
export const dateString = (locale: string) => {
  /**
   * RegExp to test a string for a full ISO 8601 Date
   * @see: https://www.myintervals.com/blog/2009/05/20/iso-8601-date-validation-that-doesnt-suck/
   */
  const ISO_8601_RE: RegExp =
    /^([+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([.,]\d+(?!:))?)?(\17[0-5]\d([.,]\d+)?)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/i;
  return z.string().regex(ISO_8601_RE, requiredFieldMessage(locale));
};

export const optionalDateString = (locale: string) =>
  dateString(locale).or(emptyString());

export const requiredNumericIdentifier = (locale: string) =>
  z
    .number()
    .int(requiredFieldMessage(locale))
    .or(z.string().regex(/^\d+$/, requiredFieldMessage(locale)));

export const requiredUuid = (locale: string) =>
  z
    .string()
    .regex(
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
      requiredFieldMessage(locale)
    );

export const emptyString = () => z.string().max(0);
