import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import ListTable from '../components/containers/listTable/ListTable';
import Button from '../forms/Button';
import theme from '../lib/theme';
import Pill from '../components/Pill';
import { useApi } from '../api/APIContext';
import { useEffect, useState } from 'react';
import { DemandeGPAClientPayload } from '../api/methods/demandes';
import { getFormattedDate } from '../lib/dateformat';
import Columns from '../components/containers/Columns';
import Spinner from '../components/Spinner';

const stateToStatus = (state: string) => {
  switch (state) {
    case 'draft':
      return 'info';
    case 'archived':
      return 'default';
    case 'submitted':
      return 'warning';
    case 'complete':
      return 'success';
    default:
      return 'default';
  }
};

const Portal = () => {
  const api = useApi();
  const [demandes, setDemandes] = useState<DemandeGPAClientPayload[] | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const creerDemande = async () => {
    if (demandes?.length) {
      const confirmation = window.confirm(
        t('common.emptyStates.alreadyexists')
      );
      if (!confirmation) return;
    }
    setIsLoading(true);
    const demande = await api.demandes.createDemande();
    if (demande) {
      window.location.href = `/wait?demandeId=${demande.demandeId}`;
    }
    setIsLoading(false);
  };
  useEffect(() => {
    api?.demandes.getDemandes().then(setDemandes);
  }, [api]);

  const { t, i18n } = useTranslation();

  const stepObjects: string[] = t('stepper.steps', {
    returnObjects: true,
  });
  return (
    <ScreenContainer>
      <SectionContainer>
        <div className='flex justify-between items-baseline'>
          <h1 className='heading1'>{t('portal.heading')}</h1>
        </div>
        <p>{t('portal.description')}</p>
      </SectionContainer>
      <SectionContainer>
        {demandes?.length ? (
          <ListTable
            titles={[
              t('portal.active.number'),
              t('portal.active.stage'),
              t('portal.active.lastModified'),
              // t('portal.active.state'),
              '',
            ]}
            elements2D={[
              ...demandes
                .sort((a, b) => {
                  try {
                    if (a.modifiedAt && b.modifiedAt) {
                      return (
                        new Date(b.modifiedAt).getTime() -
                        new Date(a.modifiedAt).getTime()
                      );
                    } else {
                      return 0;
                    }
                  } catch (e) {
                    return 0;
                  }
                })
                .map((demande) => [
                  <p>{demande.fileNumber}</p>,
                  <p>{stepObjects[demande.stage.step - 1]}</p>,
                  <p>{getFormattedDate(demande.modifiedAt, i18n.language)}</p>,
                  // <Pill
                  //   type={stateToStatus(demande.stage.status)}
                  //   text={t(`portal.stageStatus.${demande.stage.status}`)}
                  // />,
                  <a
                    href={`/dashboard?demandeId=${demande.id}`}
                    className='flex justify-between'
                  >
                    <span className='flex-grow inline-block md:white'>
                      {t('portal.active.view')}
                    </span>
                    <ArrowLongRightIcon
                      className='float-right !w-6'
                      color={theme.colors.red[200]}
                    />
                  </a>,
                ]),
            ]}
          />
        ) : demandes ? (
          <div className='p-14 text-center bg-gray-100 border'>
            <h3 className='heading4 !mb-10 !mt-0'>
              {t('portal.empty.heading')}
            </h3>
            <div className='flex flex-col justify-center !w-2/5 m-auto text-center'>
              <Button
                className='!mb-6'
                label={t('portal.empty.confirm')}
                primary
                onClick={creerDemande}
                loading={isLoading}
              />
              <Button
                label={t('portal.empty.reject')}
                href={'/requestAccess'}
              />
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </SectionContainer>
      {demandes?.length ? (
        <SectionContainer>
          <Columns numOfCols={2} className='gap-24'>
            <div className='flex flex-col items-start !h-full'>
              <span>
                <h2 className='heading3'>
                  {t('home.links.submitRequest.titleNew')}
                </h2>
                <p className='!mb-5'>
                  {t('home.links.submitRequest.description')}
                </p>
              </span>
              <Button
                label={t('home.links.submitRequest.cta.label')}
                compact
                className='inline'
                onClick={creerDemande}
                loading={isLoading}
              />

              <Button
                label={t('requestAccess.button')}
                compact
                className='inline !mt-8'
                href={'/requestAccess'}
              />
            </div>
            {/*<div className='flex flex-col items-start !h-full'>*/}
            {/*  <span>*/}
            {/*    <h3 className='heading3'>{t('home.links.contact.title')}</h3>*/}
            {/*    <p className='!mb-5'>{t('home.links.contact.description')}</p>*/}
            {/*  </span>*/}
            {/*  <Button*/}
            {/*    label={t('home.links.contact.cta.label')}*/}
            {/*    primary*/}
            {/*    className='inline'*/}
            {/*    href='/contact'*/}
            {/*  />*/}
            {/*</div>*/}
          </Columns>
        </SectionContainer>
      ) : (
        <></>
      )}
    </ScreenContainer>
  );
};

export default Portal;
