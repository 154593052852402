import ListTable from './containers/listTable/ListTable';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import Button from '../forms/Button';
import ReactMarkdown from 'react-markdown';
import SectionContainer from './containers/SectionContainer';
import { useApi } from '../api/APIContext';
import { useEffect, useState } from 'react';
import { DemandeMessage } from '../api/methods/messages';
import { truncateString } from '../utils/string.utils';
import { getFormattedDateTime } from '../lib/dateformat';
import i18n from '../i18n';
import Spinner from './Spinner';
import { DemandeGPAClientPayload } from '../api/methods/demandes';
import { formatMessageSender } from '../utils/demande-message.utils';

interface MessagesTableProps {
  demande: DemandeGPAClientPayload;
}

const messageUrl = (id: string, demandeId: string) =>
  `/message?messageId=${id}&demandeId=${demandeId}`;

const messagePropDisplay = {
  subject: ({ subject, id }: DemandeMessage, demandeId: string) => (
    <a href={messageUrl(id, demandeId)}>
      <p>{truncateString(subject, 50)}</p>
    </a>
  ),
  message: ({ message, id }: DemandeMessage, demandeId: string) => (
    <a href={messageUrl(id, demandeId)}>
      <p>{truncateString(message, 50)}</p>
    </a>
  ),
  sender: ({ sender, id }: DemandeMessage, demandeId: string) => (
    <a href={messageUrl(id, demandeId)}>
      <p>{truncateString(sender, 50)}</p>
    </a>
  ),
  createdAt: ({ createdAt, id }: DemandeMessage, demandeId: string) => (
    <a href={messageUrl(id, demandeId)}>
      <p>{getFormattedDateTime(createdAt, i18n.language)}</p>
    </a>
  ),
} as const;

function convertToArray(message: DemandeMessage, demandeId: string) {
  return (['sender', 'subject', 'createdAt'] as const).map((k) =>
    messagePropDisplay[k](message, demandeId)
  );
}

const MessagesTable = ({ demande }: MessagesTableProps) => {
  const { t } = useTranslation();
  const api = useApi();

  const [messages, setMessages] = useState<DemandeMessage[] | null>(null);

  useEffect(() => {
    api.messages
      .getMessages(demande.id)
      .then((messages) => {
        messages = messages.map((m) => {
          m.sender = formatMessageSender(demande, m);
          return m;
        });
        if (messages) {
          setMessages(
            messages?.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
          );
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [api, demande]);

  return !messages ? (
    <Spinner />
  ) : (
    <SectionContainer>
      <div className='flex items-center justify-between !mt-16 !mb-10'>
        <h2 className='heading2 !my-0'>{t('demandeMessages.messages')}</h2>
        {!demande.isClosed && (
          <Button
            compact
            href={`/newMessage?demandeId=${demande.id}`}
            icon={<EnvelopeIcon width={20} height={20} />}
            label={t('demandeMessages.sendNewMessage')}
            className='inline ml-auto'
          />
        )}
      </div>
      <div
        className={
          'max-h-[380px] overflow-y-scroll border-bottom border-gray-400'
        }
      >
        <ListTable
          titles={[
            t('demandeMessages.sentBy'),
            t('demandeMessages.subject'),
            t('demandeMessages.sentOn'),
          ]}
          elements2D={messages.map((message) =>
            convertToArray(message, demande.id)
          )}
        />
      </div>
      {(!messages || messages?.length === 0) && (
        <p className={'text-center !mb-4 text-gray-400 p-3 bg-gray-100'}>
          {t('common.emptyStates.messages')}
        </p>
      )}
      <ReactMarkdown
        className='!mt-4'
        components={{
          p: ({ ...props }) => (
            <p {...props} className='text-sm'>
              {props.children}
            </p>
          ),
        }}
      >
        {t('demandeMessages.orderNote')}
      </ReactMarkdown>
    </SectionContainer>
  );
};

export default MessagesTable;
