import SectionContainer from './containers/SectionContainer';
import Spinner from './Spinner';

export default function LoadingContainer() {
  return (
    <SectionContainer className={'text-center'}>
      <h2 className='heading2 text-center'>
        Chargement <Spinner />
      </h2>
    </SectionContainer>
  );
}
