import TableHeader from './TableHeader';
import TableRows from './TableRows';
import { ReactElement } from 'react';

interface ListTableProps {
  titles: string[];
  elements2D: ReactElement[][];
}

const ListTable = ({ titles, elements2D }: ListTableProps) => {
  const rowStyling = `grid !w-full grid-flow-col auto-cols-fr break-words`;

  return (
    <div key={`${titles}`} className='!w-full'>
      <TableHeader key={`${titles}`} titles={titles} className={rowStyling} />
      {elements2D.map((elements, i) => (
        <TableRows
          key={`${titles}${i}`}
          elements={elements}
          className={rowStyling}
        />
      ))}
    </div>
  );
};

export default ListTable;
