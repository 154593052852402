import { getUrlParam } from './navigation.utils';
import { apiErrorHandler } from '../api/apiResultHandlers';

// demandeId is a queryparam in the current url
export const getActiveDemandeId = (): string | null => getUrlParam('demandeId');

export const loadActiveDemandeId = (): string => {
  const activeDemandeId = getActiveDemandeId();
  if (!activeDemandeId) {
    return apiErrorHandler('common.toasts.noDemande');
  }
  return activeDemandeId;
};
