import { useTranslation } from 'react-i18next';
import Button, { ButtonTypes } from '../forms/Button';
import RegisteredFilePicker from '../forms/registeredFields/RegisteredFilePicker';
import { useForm } from 'react-hook-form';
import SectionContainer from './containers/SectionContainer';
import Form from '../forms/Form';
import FilesListTable from './containers/FilesListTable';
import { FilePayload } from '../api/methods/files';
import { useEffect, useState } from 'react';
import { useApi } from '../api/APIContext';
import Spinner from './Spinner';

const Files = ({
  step,
  demandeId,
  demandeStep,
  demandeIsClosed,
}: {
  step: number;
  demandeId: string;
  demandeStep: number;
  demandeIsClosed: boolean;
}) => {
  // const s = step;
  const { t } = useTranslation();
  const api = useApi();
  const [files, setFiles] = useState<FilePayload[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const refreshFiles = () => {
    api?.files.getFilesForDemande(demandeId).then(setFiles);
  };

  useEffect(() => {
    api?.files.getFilesForDemande(demandeId).then(setFiles);
  }, [api?.files, demandeId]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    // resolver: zodResolver(generateFileUploadSchema(i18n.language)),
    //TODO: Fix this, right now Zod is not letting go through files that match the conditions
    //disabling for now
  });

  const file = watch('file');

  const submitFn = (data: { file?: FileList }) => {
    const fileList = data.file;

    if (!fileList?.length) {
      return setIsLoading(false);
    }
    setIsLoading(true);

    const fileToUpload = fileList[0];

    api.files
      .uploadFile(demandeId, step, fileToUpload)
      .then(() => refreshFiles())
      .then(() => reset())
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <SectionContainer>
      <h2 className='heading2 !mt-20'>{t('dashboard.files.form.title')}</h2>
      <p className={'!mb-4'}>{t('dashboard.files.description')}</p>
      <FilesListTable
        titles={[
          t('dashboard.files.tableHeaders.file'),
          t('dashboard.files.tableHeaders.sentDate'),
        ]}
        files={files.filter((f) => f.step === step || f.step === 0)}
      />
      {(!files || files?.length === 0) && (
        <p className={'text-center !mb-4 text-gray-400 p-3 bg-gray-100'}>
          {t('common.emptyStates.files')}
        </p>
      )}
      {demandeStep === step && !demandeIsClosed && (
        <>
          <h3 className='heading3'>{t('dashboard.files.form.uploadFile')}</h3>
          <Form onSubmit={handleSubmit(submitFn)}>
            <div className='flex flex-col'>
              <div className='!w-full max-w-2xl'>
                <RegisteredFilePicker
                  name={'file'}
                  register={register}
                  label={t('dashboard.files.form.file')}
                  errorMessage={errors?.file?.message?.toString()}
                  helpText={t('dashboard.files.form.allowedType')}
                />
              </div>
              <Button
                type={ButtonTypes.submit}
                className='!mt-8 mr-auto'
                primary
                disabled={isLoading || !file}
                label={t('dashboard.files.form.button')}
                icon={isLoading ? <Spinner /> : undefined}
              />
            </div>
          </Form>
        </>
      )}
    </SectionContainer>
  );
};

export default Files;
