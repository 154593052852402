import remarkGfm from 'remark-gfm';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Tooltip, TooltipRefProps } from 'react-tooltip';
import ReactMarkdown from 'react-markdown';
import toKebabCase from '../utils/toKebabCase.utils';
import { ReactElement, useRef } from 'react';

interface CustomMarkdownProps {
  content: string;
  className?: string;
}

/**
 * CustomMarkdown component
 *
 * Use this component when you want to render markdown with custom components
 * For now, this includes rendering del tag with tooltip, and custom link style
 *
 * @param {string} content - markdown content
 * @param {string} className - custom className
 *
 * @returns {ReactElement} - React component
 */

const CustomMarkdown = ({
  content,
  className,
}: CustomMarkdownProps): ReactElement => {
  const toolTipRef = useRef<TooltipRefProps>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const handleClose = () => {
    toolTipRef.current?.close();
  };

  return (
    <ReactMarkdown
      className={className}
      remarkPlugins={[remarkGfm]}
      components={{
        a: ({ ...props }) => (
          <a {...props} className='underline' target='_blank'>
            {props.children}
          </a>
        ),
        del: (props) => {
          const id = toKebabCase(props.children.toString());

          return (
            <>
              <button id={id} type='button'>
                <InformationCircleIcon className='!h-5 !w-5 text-blue-400 pointer-events-none' />
              </button>
              <Tooltip
                ref={toolTipRef}
                openOnClick
                closeEvents={{ click: true }}
                anchorSelect={`#${id}`}
                className='!shadow-[0_2px_8px_rgba(34,54,84,0.24)] !bg-truewhite !rounded-none min-w-52 max-!w-80'
                place='right-start'
                border='1px solid #C5CAD2'
                opacity={1}
              >
                <div className='!pr-4 !py-5'>
                  <p className='text-gray-600 text-left font-openSans text-sm'>
                    {props.children}
                  </p>
                  <button
                    type='button'
                    ref={closeButtonRef}
                    onKeyDown={(e) => (e.key === 'Enter' ? handleClose() : '')}
                    className='absolute top-2 right-2 cursor-pointer'
                  >
                    <XMarkIcon className='!h-6 !w-6 text-blue-400' />
                  </button>
                </div>
              </Tooltip>
            </>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );
};

export default CustomMarkdown;
