import { z } from 'zod';
import { DemandeMessage } from '../api/methods/messages';
import { DemandeGPAClientPayload } from '../api/methods/demandes';
import { requiredString } from './zod-validators';

export const newMessageDefaultValues = {
  subject: '',
  message: '',
};

export const newMessageSchema = (locale = 'fr') =>
  z.object({
    subject: requiredString(locale),
    message: requiredString(locale),
  });

export const generateMessageReplyFormSchema = (locale: string = 'fr') =>
  z.object({
    message: requiredString(locale),
  });

export const isSqinEmail = (
  s: string | undefined
): s is `${string}@sqin.local` => !!s?.endsWith('@sqin.local');

export function formatMessageSender(
  demande: DemandeGPAClientPayload | undefined,
  message: DemandeMessage
): string {
  let messageSender = message?.sender || '';

  if (isSqinEmail(messageSender)) {
    const parent = demande?.parents?.find((p) => p.email === messageSender);
    if (parent) {
      messageSender = `${parent.firstName} ${parent.lastName}`;
    }
  }

  return messageSender;
}
