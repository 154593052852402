import { PropsWithChildren, ReactNode } from 'react';
import AlertIcon from './AlertIcon';
import { AlertType } from './types';
import { getBgClass } from './utils';
import classNames from '../utils/classList.utils';

interface AlertProps {
  header?: string;
  message?: string | ReactNode;
  type?: AlertType;
  button?: ReactNode;
}

export const Alert = ({
  children,
  header,
  message,
  type,
  button,
}: PropsWithChildren<AlertProps>) => {
  return (
    <div className='flex flex-row border border-white-300 !my-4'>
      <div
        className={classNames(
          '!h-auto text-center !py-6 !px-4',
          getBgClass(type)
        )}
      >
        <AlertIcon type={type} />
      </div>
      <div className='p-4 !py-6'>
        {children ? (
          children
        ) : (
          <>
            {header && (
              <h2 className='text-base font-bold paragraph'>{header}</h2>
            )}
            <p className='text-base'>{message}</p>
            {button}
          </>
        )}
      </div>
    </div>
  );
};
