import { DemandeGPAClientPayload } from '../api/methods/demandes';
import { SignatureStatus } from './types';
import { GpaAPI } from '../api/APIContext';
import { downloadHTML, generatePDF } from './pageToPdf';

export function determineSignatureState(
  demande: DemandeGPAClientPayload | undefined,
  signingStatus: SignatureStatus | undefined
) {
  const selfPINumber = getSelfParentIdx(demande) + 1;

  const selfParentHasSigned =
    selfPINumber === 1 ? signingStatus?.signed_pi1 : signingStatus?.signed_pi2;

  const otherPINumber = getOtherParentIdx(demande) + 1;

  const otherParentHasSigned =
    otherPINumber > 0
      ? otherPINumber === 1
        ? signingStatus?.signed_pi1
        : signingStatus?.signed_pi2
      : undefined;

  const signaturesRequiredCount = demande?.parents?.filter(Boolean).length || 1;

  const numberOfMissingSignatures =
    signaturesRequiredCount -
    (selfParentHasSigned ? 1 : 0) -
    (otherParentHasSigned ? 1 : 0);

  return {
    signaturesRequiredCount,
    selfParentHasSigned,
    otherParentHasSigned,
    someoneHasSigned: !!selfParentHasSigned || !!otherParentHasSigned,
    numberOfMissingSignatures,
    isSigningComplete: numberOfMissingSignatures === 0,
  };
}

export interface SignatureState {
  selfParentHasSigned?: string;
  otherParentHasSigned?: string;
  someoneHasSigned: boolean;
  numberOfMissingSignatures: number;
}

const getSelfParentIdx = (
  demande: DemandeGPAClientPayload | undefined
): number => demande?.parents?.findIndex((p) => p.isSelf) ?? -1;

const getOtherParentIdx = (
  demande: DemandeGPAClientPayload | undefined
): number => demande?.parents?.findIndex((p) => !p.isSelf) ?? -1;

const getSelfParent = (demande: DemandeGPAClientPayload | undefined) =>
  demande?.parents?.[getSelfParentIdx(demande)];

export function getOtherParent(demande: DemandeGPAClientPayload | undefined) {
  const parents = demande?.parents ?? [];
  if (parents.length < 2) {
    return undefined;
  }
  return parents.find((p) => !p.isSelf);
}

export const getDemandeParents = (
  demande: DemandeGPAClientPayload | undefined
) => ({
  selfParent: getSelfParent(demande),
  otherParent: getOtherParent(demande),
  firstParent: demande?.parents?.[0],
  secondParent: demande?.parents?.[1],
});

export function waitSeconds(seconds: number): Promise<void> {
  return new Promise((r) => setTimeout(r, seconds * 1000));
}

export const uploadFormHtml = async (
  htmlSelector: string,
  api: GpaAPI,
  demande: DemandeGPAClientPayload,
  defaultStep: number = demande.stage?.step
) => {
  const html = downloadHTML(htmlSelector);
  if (html && demande) {
    await api.files.uploadHtmlFile(
      demande.id,
      demande.stage?.step || defaultStep,
      `${htmlSelector}.html`,
      html
    );
  }
};

export const uploadPDFForm = async (
  pdfSelector: string,
  api: GpaAPI,
  demande: DemandeGPAClientPayload,
  formName: string,
  defaultStep: number = demande.stage?.step
) => {
  const pdf = await generatePDF(pdfSelector);

  if (pdf && demande) {
    //file from pdf buffer
    const file = new File([pdf], `[FORMULAIRE] ${formName}.pdf`, {
      type: 'application/pdf',
    });

    await api.files.uploadFile(
      demande.id,
      demande.stage?.step || defaultStep,
      file
    );
  }
};
