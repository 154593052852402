import { SignatureState } from '../utils/authFormCommon.utils';
import { Alert } from '../alerts/Alert';
import Button, { ButtonTypes } from './Button';
import SectionContainer from '../components/containers/SectionContainer';
import { goToActiveDemande } from '../utils/navigation.utils';
import { useTranslation } from 'react-i18next';

const SignatureStateSection = ({
  signatureState,
}: {
  signatureState: SignatureState;
}) => {
  const { t } = useTranslation();
  const { numberOfMissingSignatures, someoneHasSigned } = signatureState;

  return (
    <SectionContainer>
      {numberOfMissingSignatures > 0 && someoneHasSigned && (
        <Alert
          header={t('common.signature.partiallySignedForm')}
          message={t('common.signature.otherMustSign')}
          type={'warning'}
        />
      )}
      {numberOfMissingSignatures === 0 && someoneHasSigned && (
        <Alert
          header={t('common.signature.signedForm')}
          message={t('common.signature.noOtherMustSign')}
          type={'success'}
          button={
            <Button
              compact
              className={'!mt-4'}
              onClick={goToActiveDemande}
              type={ButtonTypes.button}
              label={t('common.signature.returnToDashboard')}
            />
          }
        />
      )}
    </SectionContainer>
  );
};

export default SignatureStateSection;
