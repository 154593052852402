import { dataverseFetch, dataverseFetchWithNotification } from './http';

export interface MessageCreation {
  subject: string;
  message: string;
  demandeId: string;
}

export interface MessageReplyDto {
  replyToMessageId: string;
  message: string;
}

export interface DemandeMessage {
  id: string;
  subject: string;
  message: string;
  sender: string;
  createdAt: string;
  demandeId: string;
  conversationId: string;
  fileNumber: string;
  inReplyToMessageId?: string;
  files?: {
    fileName: string;
    id: string;
  }[];
}

export const getMessageById = async (messageId: string) =>
  dataverseFetch<DemandeMessage>(`/messages/${messageId}`, { method: 'GET' });
export const getMessages = async (demandeId: string) => {
  return dataverseFetch<DemandeMessage[]>(`/demandes/${demandeId}/messages`, {
    method: 'GET',
  });
};

export const createMessage = async (data: MessageCreation) => {
  return dataverseFetchWithNotification(
    `/demandes/${data.demandeId}/messages`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );
};

export const replyToMessage = async (data: MessageReplyDto) => {
  return dataverseFetchWithNotification(
    `/messages/${data.replyToMessageId}/reply`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );
};
