import Step from './Step';

export enum StepperStatus {
  Complete = 'complete',
  Current = 'current',
  Upcoming = 'upcoming',
}

interface StepperProps {
  steps: {
    name: string;
    status: StepperStatus;
  }[];
  step: number;
  reqStatus: 'draft' | 'confirmed';
}

const Stepper = ({ steps, step, reqStatus }: StepperProps) => {
  return (
    <nav aria-label='Progress'>
      <ol className={`grid lg:grid-cols-${steps.length}`}>
        {steps.map((s, stepIdx) => (
          <li key={s.name} className='relative !pr-6 sm:!pr-15'>
            <Step
              step={s}
              stepIdx={stepIdx}
              status={s.status}
              stepNum={step}
              reqStatus={reqStatus}
            />
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Stepper;
