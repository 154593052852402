import { z } from 'zod';
import { ParentPayload, ParentSavePayload } from '../api/methods/demandes';
import { translateLanguage } from './translateLanguage.utils';

export const personalInfoDefaultValues = (
  parent: ParentPayload
): ParentSavePayload => ({
  lastName: parent.lastName,
  firstName: parent.firstName,
  address: parent.address || '',
  city: parent.city || '',
  postalCode: parent.postalCode || '',
  dob: parent.dob,
  telephone: parent.telephone || '',
  ville: parent.ville || '',
  pays: parent.pays || '',
  gender: parent.gender || undefined,
  preferredLanguage: parent.preferredLanguage || undefined,
});

export const personalInfoSchema = (locale = 'fr') =>
  z.object({
    lastName: z.string(),
    firstName: z.string(),
    address: z.string().optional(),
    city: z.string().optional(),
    postalCode: z.string().optional(),
    dob: z.string(),
    telephone: z
      .string()
      .optional()
      .refine((e) => (e ? e.replace(/[a-zA-z]/g, '').length >= 7 : true), {
        message: translateLanguage('common.forms.invalid.phoneNumber', locale),
      }),
  });
