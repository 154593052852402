import { useTranslation } from 'react-i18next';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import Columns from '../components/containers/Columns';
import Button from '../forms/Button';
import Stepper from '../components/Stepper';
import StepPagination from '../components/StepPagination';
import { useEffect, useState } from 'react';
import { steps } from '../utils/stepNavUtils';
import Steps from '../components/Steps';
import { useApi } from '../api/APIContext';
import LoadingContainer from '../components/LoadingContainer';

const Home = () => {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const currentIndex = 0;
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const stepObjects: string[] = t('stepper.steps', {
    returnObjects: true,
  });

  const api = useApi();

  useEffect(() => {
    api?.demandes
      .getDemandes()
      .then((demandes) => {
        if (demandes?.length > 0) {
          window.location.href = '/portal';
        } else {
          setLoaded(true);
        }
      })
      .catch(() => {
        setLoaded(true);
      });
  }, [api]);
  const creerDemande = async () => {
    setIsLoading(true);

    const demande = await api.demandes.createDemande();
    if (demande) {
      window.location.href = `/wait?demandeId=${demande.demandeId}`;
    }
    setIsLoading(false);
  };
  const stepsData = steps(stepObjects, currentIndex);

  if (!loaded)
    return (
      <ScreenContainer>
        <LoadingContainer />
      </ScreenContainer>
    );
  return (
    <ScreenContainer>
      <SectionContainer>
        <h1 className='heading1'>{t('home.intro.title')}</h1>
        <p className='!mb-4 heading5'>{t('home.intro.subtitle')}</p>
        <p>{t('home.intro.content')}</p>
      </SectionContainer>
      <SectionContainer>
        <h2 className='heading2'>{t('stepper.title')}</h2>
      </SectionContainer>

      <SectionContainer>
        <Stepper steps={stepsData} step={step} reqStatus={'draft'} />
        <Steps step={step} />
        <StepPagination
          step={step}
          setStep={setStep}
          steps={stepObjects}
          anchor='stepTitle'
        />
      </SectionContainer>

      <SectionContainer>
        <Columns numOfCols={2} className='gap-24'>
          <div className='flex flex-col items-start !h-full'>
            <span>
              <h2 className='heading2'>
                {t('home.links.submitRequest.title')}
              </h2>
              <p className='!mb-5'>
                {t('home.links.submitRequest.description')}
              </p>
            </span>
            <Button
              label={t('home.links.submitRequest.cta.label')}
              primary
              className='inline'
              onClick={creerDemande}
              loading={isLoading}
            />

            <Button
              label={t('changeRequest.button')}
              compact
              className='inline !mt-8'
              href={'/requestAccess'}
            />
          </div>
          <div className='flex flex-col items-start !h-full'>
            <span>
              <h3 className='heading2'>{t('home.links.contact.title')}</h3>
              <p className='!mb-5'>{t('home.links.contact.description')}</p>
            </span>
            <Button
              label={t('home.links.contact.cta.label')}
              primary
              className='inline'
              href='/contact'
            />
          </div>
        </Columns>
      </SectionContainer>
    </ScreenContainer>
  );
};

export default Home;
