import { ParentOnDemande } from '../api/methods/demandes';
import Pill from '../components/Pill';
import { getFormattedDateTime } from '../lib/dateformat';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

interface OtherParentSignatureSectionProps {
  otherParent?: ParentOnDemande;
  otherParentSignatureDate?: string;
}

const OtherParentSignatureSection = ({
  otherParent,
  otherParentSignatureDate,
}: OtherParentSignatureSectionProps) => {
  const { t } = useTranslation();

  if (!otherParent) {
    return null;
  }

  return (
    <div className='flex flex-col'>
      <h4 className={'heading4 !mb-2 space-x-4'}>
        <span>
          {otherParent?.firstName} {otherParent?.lastName}
        </span>
        {otherParentSignatureDate ? (
          <Pill type={'success'} text={t('common.signature.signedLabel')} />
        ) : (
          <Pill type={'warning'} text={t('common.signature.toSignLabel')} />
        )}
      </h4>
      {otherParentSignatureDate ? (
        <p className={'!mb-8'}>
          {t('common.signature.signed', {
            name: `${otherParent?.firstName} ${otherParent?.lastName}`,
            time: getFormattedDateTime(otherParentSignatureDate, i18n.language),
          })}
        </p>
      ) : (
        <p className={'!mb-8'}>
          {t('common.signature.mustSign', {
            name: `${otherParent?.firstName} ${otherParent?.lastName}`,
          })}
        </p>
      )}
    </div>
  );
};

export default OtherParentSignatureSection;
