import { z } from 'zod';
import { translateLanguage } from './translateLanguage.utils';
import {
  optionalDateString,
  requiredFieldMessage,
  requiredNumericIdentifier,
  requiredString,
  requiredUuid,
} from './zod-validators';

const statutConjugaleToMarriageType = {
  '263270000': 'married',
  '190330001': 'commonLaw',
} as const;

type StatutConjugaleType = keyof typeof statutConjugaleToMarriageType;
const etatsCivils = Object.keys(
  statutConjugaleToMarriageType
) as StatutConjugaleType[];
const isStatutConjugale = (
  value: string | number
): value is StatutConjugaleType =>
  etatsCivils.includes(value as StatutConjugaleType);

export type MarriageType =
  | 'married'
  | 'civilUnion'
  | 'commonLaw'
  | 'single'
  | '';

export type MarriageScenario = 'single' | 'coupleMarriage' | 'coupleCommonlaw';

const marriageTypeToScenario: Record<MarriageType, MarriageScenario> = {
  married: 'coupleMarriage',
  civilUnion: 'coupleMarriage',
  commonLaw: 'coupleCommonlaw',
  single: 'single',
  '': 'single',
};

const asMarriageType = (
  mType: MarriageType | StatutConjugaleType
): MarriageType => {
  if (isStatutConjugale(mType)) {
    return statutConjugaleToMarriageType[mType];
  }
  return mType;
};

export const getMarriageScenario = (
  marriageType: MarriageType | StatutConjugaleType
): MarriageScenario => marriageTypeToScenario[asMarriageType(marriageType)];

export const isDocumentedMarriageType = (
  rawType: MarriageType | StatutConjugaleType
): boolean => {
  const mType = asMarriageType(rawType);
  return mType === 'married' || mType === 'civilUnion';
};

export interface PriorAuthPayload {
  canadianCitizenshipProofExpirationP1: string;
  canadianCitizenshipProofSelectP1: string;
  certificationDateP1: string;
  resultsDateP1: string;
  chosenState: string;
  dateOfDeclaration: string;
  declarationNumber: string;
  parent1Consent: string;
  parent1DomicileP1: string;
  parent1DomicileP2: string;
  parent1IdExp1: string;
  parent1IdExp2: string;
  parent1IdProof1: string;
  parent1IdProof2: string;
  parent1Residence: string;
  parent1Signature: boolean;

  canadianCitizenshipProofExpirationP2: string;
  canadianCitizenshipProofSelectP2: string;
  certificationDateP2: string;
  resultsDateP2: string;
  marriageType: MarriageType;
  marriageDocument: string;
  parent2Consent: string;
  parent2DomicileP1: string;
  parent2DomicileP2: string;
  parent2IdExp1: string;
  parent2IdExp2: string;
  parent2IdProof1: string;
  parent2IdProof2: string;
  parent2Residence: string;
  parent2Signature: boolean;
}

const residenceProofEnum = (locale: string = 'fr') => {
  // These come from remote values, with ID like 263270000
  return requiredNumericIdentifier(locale);
  // return z.enum(
  //   [
  //     'recordOfEmployment',
  //     'healthCard',
  //     'passport',
  //     'citizenCard',
  //     'residentCard',
  //     'greenCard',
  //     'officialIdentifier',
  //     'indianStatus',
  //   ],
  //   {
  //     message: translateLanguage('common.forms.required', locale),
  //   }
  // );
};

const idProofDocSchema = (locale: string = 'fr') =>
  z.object({
    docType: requiredNumericIdentifier(locale),
    expiration: requiredString(locale),
  });

const parentSchema = (locale: string = 'fr') =>
  z
    .object({
      firstName: requiredString(locale),
      lastName: requiredString(locale),
      residence: requiredNumericIdentifier(locale),
      idProof1: idProofDocSchema(locale),
      idProof2: idProofDocSchema(locale),
      residenceProofType1: residenceProofEnum(locale),
      residenceProofType2: residenceProofEnum(locale),
      citizenshipProof: z.object({
        docType: requiredNumericIdentifier(locale),
        expiration: optionalDateString(locale),
      }),

      certOfAttendanceDate: requiredString(locale),
      criminalRecordCheckDate: requiredString(locale),

      consent: z.enum(['yes', 'no'], {
        message: requiredFieldMessage(locale),
      }),
    })
    .refine(
      ({ idProof1, idProof2 }) => {
        return idProof1.docType !== idProof2.docType;
      },
      {
        message: translateLanguage(
          'common.forms.invalid.repeatedDocType',
          locale
        ),
        path: ['idProof2.docType'],
      }
    )
    .refine(
      ({ residenceProofType1, residenceProofType2 }) => {
        return residenceProofType1 !== residenceProofType2;
      },
      {
        message: translateLanguage(
          'common.forms.invalid.repeatedDocType',
          locale
        ),
        path: ['residenceProofType2'],
      }
    );

const commonFields = (locale: string = 'fr') => {
  return {
    chosenState: requiredUuid(locale),
    dateOfDeclaration: requiredString(locale),
    declarationNumber: requiredString(locale),
  };
};

const marriageType = (locale: string = 'fr') => {
  return {
    marriageType: z.enum(
      [
        'married',
        'civilUnion',
        'commonLaw',
        ...etatsCivils,
      ] as const satisfies ReadonlyArray<StatutConjugaleType | MarriageType>,
      {
        message: requiredFieldMessage(locale),
      }
    ),
  };
};

export const generatePriorAuthSchema = (locale: string = 'fr') => {
  return z.discriminatedUnion('scenario', [
    z.object({
      scenario: z.literal('single' as const satisfies MarriageScenario),
      ...commonFields(locale),
      parent1: parentSchema(locale),
    }),
    z.object({
      scenario: z.literal(
        'coupleCommonlaw' as const satisfies MarriageScenario
      ),
      parent1: parentSchema(locale),
      parent2: parentSchema(locale),
      ...commonFields(locale),
      ...marriageType(locale),
    }),
    z.object({
      scenario: z.literal('coupleMarriage' as const satisfies MarriageScenario),
      parent1: parentSchema(locale),
      parent2: parentSchema(locale),
      ...commonFields(locale),
      ...marriageType(locale),
      marriageDocument: requiredNumericIdentifier(locale),
    }),
  ]);
};

export const PriorAuthSchemaVal = generatePriorAuthSchema();
export type PriorAuthSchema = z.infer<typeof PriorAuthSchemaVal>;
