import { LegacyRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useApi } from '../../api/APIContext';
import { ParentPayload, ParentSavePayload } from '../../api/methods/demandes';
import Form from '../../forms/Form';
import RegisteredTextField from '../../forms/registeredFields/RegisteredTextField';
import { personalInfoDefaultValues } from '../../utils/personalInfo.util';
import Columns from '../containers/Columns';
import RegisteredSelect from '../../forms/registeredFields/RegisteredSelect';
import { choiceTypeToSelectOptions } from '../../forms/hooks/useFetchedSelectOptions';

interface ParentFormProps {
  parent: ParentPayload;
  possibleEtats: ChoiceList[];
  formRef: LegacyRef<HTMLFormElement>;
  disabled?: boolean;
}

const noop = () => undefined;

const ParentForm = ({
  possibleEtats,
  parent,
  formRef,
  disabled = false,
}: ParentFormProps) => {
  const { t, i18n } = useTranslation();

  const api = useApi();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: personalInfoDefaultValues(parent),
    // resolver: zodResolver(personalInfoSchema(i18n.language)),
  });

  const submitFn = disabled
    ? noop
    : (data: ParentSavePayload) => {
        api.demandes.saveParent(parent.id, data).catch(console.error);
      };

  const languageDropDown = [
    {
      value: 263270000,
      label: t('personalInfo.parentForm.fr'),
    },
    {
      value: 263270001,
      label: t('personalInfo.parentForm.en'),
    },
  ];

  const genderDropDown = [
    {
      value: 263270000,
      label: t('personalInfo.parentForm.genders.male'),
    },
    {
      value: 263270001,
      label: t('personalInfo.parentForm.genders.female'),
    },
    {
      value: 263270002,
      label: t('personalInfo.parentForm.genders.transgender'),
    },
    {
      value: 263270003,
      label: t('personalInfo.parentForm.genders.nonbinary'),
    },
    {
      value: 263270004,
      label: t('personalInfo.parentForm.genders.other'),
    },
  ];

  const etatsOptions = choiceTypeToSelectOptions(possibleEtats, i18n.language);

  return (
    <Form onSubmit={handleSubmit(submitFn)} formRef={formRef}>
      <fieldset disabled={disabled}>
        <RegisteredTextField
          name={'lastName'}
          label={t('personalInfo.parentForm.familyName')}
          register={register}
          readonly
        />
        <RegisteredTextField
          name={'firstName'}
          label={t('personalInfo.parentForm.firstName')}
          register={register}
          readonly
        />
        <RegisteredSelect
          name={'gender'}
          label={t('personalInfo.parentForm.gender')}
          placeholder={t('personalInfo.parentForm.gender')}
          options={genderDropDown}
          register={register}
        />
        <Columns numOfCols={2}>
          <RegisteredSelect
            name={'pays'}
            label={t('personalInfo.parentForm.pays')}
            placeholder={t('personalInfo.parentForm.pays')}
            options={etatsOptions}
            register={register}
          />
          <RegisteredTextField
            name={'ville'}
            label={t('personalInfo.parentForm.ville')}
            register={register}
          />
        </Columns>
        <RegisteredTextField
          name={'address'}
          label={t('personalInfo.parentForm.address')}
          register={register}
          errorMessage={errors.address?.message}
        />
        <Columns numOfCols={2}>
          <RegisteredTextField
            name={'city'}
            label={t('personalInfo.parentForm.city')}
            register={register}
            errorMessage={errors.city?.message}
          />
          <RegisteredTextField
            name={'postalCode'}
            label={t('personalInfo.parentForm.postalCode')}
            register={register}
            errorMessage={errors.postalCode?.message}
          />
        </Columns>
        <RegisteredTextField
          name={'dob'}
          label={t('personalInfo.parentForm.birthday')}
          register={register}
          readonly
        />
        <RegisteredTextField
          name={'telephone'}
          label={t('personalInfo.parentForm.telephone')}
          register={register}
          errorMessage={errors.telephone?.message}
        />
        <RegisteredSelect
          name={'preferredLanguage'}
          label={t('personalInfo.parentForm.preferred_language')}
          placeholder={t('personalInfo.parentForm.preferred_language')}
          options={languageDropDown}
          defaultValue={parent?.preferredLanguage}
          register={register}
        />
        <ReactMarkdown
          className='!mb-8'
          components={{
            p: ({ ...props }) => (
              <p {...props} className='text-sm'>
                {props.children}
              </p>
            ),
            a: ({ ...props }) => (
              <a {...props} className='text-sm underline' target='_blank'>
                {props.children}
              </a>
            ),
          }}
        >
          {t('personalInfo.parentForm.emailNote')}
        </ReactMarkdown>
      </fieldset>
    </Form>
  );
};

export default ParentForm;
