import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import Button from '../forms/Button';
import Spinner from '../components/Spinner';
import { useEffect } from 'react';
import { useApi } from '../api/APIContext';
import { useTranslation } from 'react-i18next';

const WaitForDemande = () => {
  const api = useApi();
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      api.demandes
        .getActivedemande()
        .then((demande) => {
          if (demande) {
            window.location.href = `/dashboard?demandeId=${demande.id}`;
          }
        })
        .catch(() => {});
    }, 1000);

    return () => clearInterval(interval);
  }, [api.demandes]);

  return (
    <ScreenContainer>
      <SectionContainer>
        <h1 className='heading1'>
          {t('loading.title')}&nbsp;
          <Spinner className={'!w-6 !h-6'} />
        </h1>
        <p>{t('loading.description')}</p>
      </SectionContainer>
      <SectionContainer>
        <Button label={t('loading.button')} href={'/portal'} />
      </SectionContainer>
    </ScreenContainer>
  );
};

export default WaitForDemande;
