import { LegacyRef } from 'react';
import classNames from '../utils/classList.utils';
import { Size, sizeToWidth } from '../utils/fieldSize.utils';

export interface TextFieldProps {
  defaultValue?: string;
  /**
   * HTML name attribute of the field
   */
  name: string;
  /**
   * HTML type attribute
   */
  type?: 'text' | 'password' | 'email' | 'number' | 'tel';
  /**
   * Field width
   */
  size?: Size;
  required?: boolean;
  label: string;
  placeholder?: string;
  /**
   * Sub-label details
   */
  details?: string;
  errorMessage?: string;
  /**
   * Optional ref for the input element
   */
  textFieldRef?: LegacyRef<HTMLInputElement>;
  hidden?: boolean;
  readonly?: boolean;
}

export default function TextField({
  details,
  errorMessage,
  hidden,
  label,
  required,
  textFieldRef,
  defaultValue,
  size = 'full',
  type = 'text',
  placeholder,
  readonly,
  ...inputProps
}: TextFieldProps) {
  const { name } = inputProps;

  return (
    <div className={classNames('!mb-8', hidden ? 'hidden' : '')}>
      <label
        htmlFor={name}
        className={classNames('block font-bold', details ? '!mb-0' : '!mb-2')}
      >
        {label}
        {required && !readonly && (
          <span className='font-bold text-red-300'>&nbsp;*</span>
        )}
      </label>
      {details && <p className='!mb-1 text-sm'>{details}</p>}
      <input
        type={type}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...inputProps}
        readOnly={readonly}
        ref={textFieldRef}
        id={name}
        className={classNames(
          sizeToWidth(size),
          'paragraph appearance-none !w-full text-gray-700 leading-tight !h-10 placeholder:text-gray-300',
          readonly
            ? 'border-0 p-0 !focus:outline-none !focus:ring-0'
            : 'border !py-2 !px-3 !focus:border-gray-500 focus:border-2 !focus:outline-offset-2 !focus:outline-2 !focus:outline-blue-100',
          errorMessage ? 'border-red-300 border-2' : '!border-gray-300'
        )}
      />
      {errorMessage && (
        <p className='!mt-1 text-sm font-bold text-red-300'>{errorMessage}</p>
      )}
    </div>
  );
}
