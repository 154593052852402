import classNames from '../utils/classList.utils';

export type PillTypes = 'info' | 'warning' | 'success' | 'error' | 'default';

interface PillProps {
  type: PillTypes;
  text: string;
}

const Pill = ({ type, text }: PillProps) => {
  const pillTypeDictionary = {
    info: 'bg-blue-100 text-blue-600',
    warning: 'bg-yellow-100 text-yellow-600',
    success: 'bg-green-100 text-green-600',
    error: 'bg-red-100 bg-red-600',
    default: 'bg-gray-100 text-gray-600',
  };

  return (
    <span
      className={classNames(
        'gpa-pill !px-2 !py-1 text-sm font-openSans font-semibold',
        pillTypeDictionary[type]
      )}
    >
      {text}
    </span>
  );
};

export default Pill;
