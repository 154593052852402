import { useTranslation } from 'react-i18next';
import Columns from '../components/containers/Columns';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import AddParentCard from '../components/personalInfo/AddParentCard';
import ParentForm from '../components/personalInfo/ParentForm';
import Button from '../forms/Button';
import { useEffect, useRef, useState } from 'react';
import { ParentPayload } from '../api/methods/demandes';
import { useApi } from '../api/APIContext';
import BackButton from '../components/BackButton';
import CustomMarkdown from '../components/CustomMarkdown';
import IdentityVerification from '../components/IdentityVerification';
import { useActiveDemande } from '../forms/hooks/useActiveDemande';
import { Alert } from '../alerts/Alert';

interface LinkingInfo {
  code: string;
  isUnique: boolean;
  canLink: boolean;
}

const PersonalInfo = () => {
  const [parents, setParents] = useState<
    (ParentPayload & { isSelf: boolean })[]
  >([]);
  const [possibleEtats, setPossibleEtats] = useState<ChoiceList[]>([]);

  const [linkingInfo, setLinkingInfo] = useState<LinkingInfo>();
  const { t } = useTranslation();
  const api = useApi();

  const demande = useActiveDemande();
  const canNoLongerBeEdited = !demande || demande.stage?.step > 2;

  const formRef1 = useRef<HTMLFormElement>(null);
  const formRef2 = useRef<HTMLFormElement>(null);

  const idFormRef1 = useRef<HTMLFormElement>(null);
  const idFormRef2 = useRef<HTMLFormElement>(null);

  const firstParent = parents[0];
  const secondParent = parents[1];

  const canLink = firstParent && !secondParent && !canNoLongerBeEdited;

  useEffect(() => {
    api.demandes.getParents().then((p) => {
      setParents(p);
    });
    api?.demandes?.getPersonalInfoFormTypes().then(setPossibleEtats);
  }, [api]);
  useEffect(() => {
    if (canLink) {
      api.demandes.getLinkingInformation().then(setLinkingInfo);
    }
  }, [api, canLink]);

  const shouldShowEditingWarning = !!(demande && canNoLongerBeEdited);
  const shouldShowSecondParentSection = !!(canLink || secondParent);
  if (demande?.isClosed) {
    return <BackButton />;
  }
  return (
    <ScreenContainer>
      <BackButton />

      <SectionContainer>
        <h1 className='heading1'>{t('personalInfo.heading')}</h1>

        {shouldShowEditingWarning && (
          <Alert message={t('personalInfo.editingDisabled')} type={'warning'} />
        )}

        <Columns numOfCols={2} className='md:gap-24 !mb-8'>
          <div>
            <h3 className='!mt-8 heading3'>
              {secondParent
                ? t('personalInfo.parentForm.heading', { number: '1' })
                : t('personalInfo.parentForm.heading', { number: '' })}
            </h3>
            {firstParent && (
              <>
                <ParentForm
                  possibleEtats={possibleEtats}
                  parent={firstParent}
                  formRef={formRef1}
                  disabled={canNoLongerBeEdited}
                />

                {!canNoLongerBeEdited && (
                  <IdentityVerification
                    formRef={idFormRef1}
                    parent={firstParent}
                  />
                )}
              </>
            )}
          </div>
          {shouldShowSecondParentSection && (
            <div>
              <h3 className='!mt-8 heading3'>
                {t('personalInfo.parentForm.heading', { number: '2' })}
              </h3>
              {secondParent ? (
                <>
                  <ParentForm
                    possibleEtats={possibleEtats}
                    parent={secondParent}
                    formRef={formRef2}
                    disabled={canNoLongerBeEdited}
                  />

                  {!canNoLongerBeEdited && (
                    <IdentityVerification
                      formRef={idFormRef2}
                      parent={secondParent}
                    />
                  )}
                </>
              ) : (
                linkingInfo && (
                  <AddParentCard
                    intendedParent={firstParent?.lastName}
                    code={linkingInfo?.code}
                    isUnique={linkingInfo?.isUnique}
                    canLink={linkingInfo?.canLink}
                  />
                )
              )}
            </div>
          )}
        </Columns>
        <hr className={'!mb-8'} />
        {!canNoLongerBeEdited && (
          <Button
            onClick={async () => {
              idFormRef1.current?.requestSubmit();
              idFormRef2.current?.requestSubmit();
              formRef1.current?.requestSubmit();
              formRef2.current?.requestSubmit();
            }}
            label={t('common.forms.save')}
            primary
          />
        )}
        <BackButton />
      </SectionContainer>
      {firstParent && secondParent && (
        <SectionContainer className='!mt-20'>
          <h2 className='heading2'>
            {t('personalInfo.parentForm.twoParentNote.heading')}
          </h2>
          <CustomMarkdown
            className='!mb-5 whitespace-pre-wrap'
            content={t('personalInfo.parentForm.twoParentNote.paragraph')}
          />
        </SectionContainer>
      )}
    </ScreenContainer>
  );
};

export default PersonalInfo;
