import i18n from '../i18n';
import { translateLanguage } from '../utils/translateLanguage.utils';

const language = i18n.language;
export const idProofSelection = [
  {
    value: 'driversLicense',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.idProof.selection.driversLicense',
      language
    ),
  },
  {
    value: 'healthCard',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.idProof.selection.healthCard',
      language
    ),
  },
  {
    value: 'passport',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.idProof.selection.passport',
      language
    ),
  },
  {
    value: 'citizenCard',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.idProof.selection.citizenCard',
      language
    ),
  },
  {
    value: 'residentCard',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.idProof.selection.residentCard',
      language
    ),
  },
  {
    value: 'greenCard',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.idProof.selection.greenCard',
      language
    ),
  },
  {
    value: 'officialIdentifier',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.idProof.selection.officialIdentifier',
      language
    ),
  },
  {
    value: 'indianStatus',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.idProof.selection.indianStatus',
      language
    ),
  },
  {
    value: 'other',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.idProof.selection.other',
      language
    ),
  },
];

export const addressProofSelection = [
  {
    value: 'recordOfEmployment',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.proofOfDomicile.selection.recordOfEmployment',
      language
    ),
  },
  {
    value: 'healthCard',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.proofOfDomicile.selection.qcLisence',
      language
    ),
  },
  {
    value: 'passport',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.proofOfDomicile.selection.taxBill',
      language
    ),
  },
  {
    value: 'citizenCard',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.proofOfDomicile.selection.billStatement',
      language
    ),
  },
  {
    value: 'residentCard',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.proofOfDomicile.selection.insuranceProof',
      language
    ),
  },
  {
    value: 'greenCard',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.proofOfDomicile.selection.creditStatement',
      language
    ),
  },
  {
    value: 'officialIdentifier',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.proofOfDomicile.selection.transcript',
      language
    ),
  },
  {
    value: 'indianStatus',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.proofOfDomicile.selection.taxReturn',
      language
    ),
  },
];

export const canadianCitizenshipSelection = [
  {
    value: 'recordOfEmployment',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.canadianCitizenship.proofType.selection.passport',
      language
    ),
  },
  {
    value: 'healthCard',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.canadianCitizenship.proofType.selection.birthCert',
      language
    ),
  },
  {
    value: 'passport',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.canadianCitizenship.proofType.selection.citizenShip',
      language
    ),
  },
  {
    value: 'citizenCard',
    label: translateLanguage(
      'priorAuth.form.documentsAttach.canadianCitizenship.proofType.selection.permResidence',
      language
    ),
  },
];

export const marriageTypeSelection = [
  {
    label: translateLanguage(
      'priorAuth.form.maritalStatus.unionType.radio.marriage',
      language
    ),
    value: 'married',
  },
  {
    label: translateLanguage(
      'priorAuth.form.maritalStatus.unionType.radio.civilUnion',
      language
    ),
    value: 'civilUnion',
  },
  {
    label: translateLanguage(
      'priorAuth.form.maritalStatus.unionType.radio.commonLaw',
      language
    ),
    value: 'commonLaw',
  },
];

export const marriageDocumentSelection = [
  {
    label: translateLanguage(
      'priorAuth.form.maritalStatus.document.radio.marriage',
      language
    ),
    value: 'marriageCertificate',
  },
  {
    label: translateLanguage(
      'priorAuth.form.maritalStatus.document.radio.civilUnion',
      language
    ),
    value: 'civilUnionCertificate',
  },
];

export const parentResidenceSelection = [
  {
    label: translateLanguage('priorAuth.form.parents.select.citizen', language),
    value: 'citizen',
  },
  {
    label: translateLanguage(
      'priorAuth.form.parents.select.permanentResident',
      language
    ),
    value: 'permanentResident',
  },
  {
    label: translateLanguage(
      'priorAuth.form.parents.select.tempResident',
      language
    ),
    value: 'temporaryResident',
  },
  {
    label: translateLanguage('priorAuth.form.parents.select.refugee', language),
    value: 'refugee',
  },
];

export const designatedStatesSelection = [
  {
    label: 'Alberta',
    value: 'AL',
  },
  {
    label: 'Colombie-Britannique',
    value: 'BC',
  },
  {
    label: 'Île-du-Prince-Édouard',
    value: 'PEI',
  },
  {
    label: 'Manitoba',
    value: 'MA',
  },
  {
    label: 'Nouvelle-Écosse',
    value: 'NE',
  },
  {
    label: 'Ontario',
    value: 'ON',
  },
  {
    label: 'Saskatchewan',
    value: 'SK',
  },
  {
    label: 'États-Unis',
    value: 'USA',
  },
  {
    label: 'Australie',
    value: 'AUS',
  },
];

export const consentSelection = [
  {
    label: translateLanguage('priorAuth.form.consent.parent.confirm', language),
    value: 'yes',
  },
  {
    label: translateLanguage('priorAuth.form.consent.parent.reject', language),
    value: 'no',
  },
];
