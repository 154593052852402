import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  LightBulbIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { AlertType, AlertTypes } from './types';

interface AlertIconProps {
  type?: AlertType;
}

const AlertIcon = ({ type }: AlertIconProps) => {
  switch (type) {
    case AlertTypes.info:
      return (
        <InformationCircleIcon
          className='!w-10 !h-10 text-blue-600'
          strokeWidth='1'
        />
      );
    case AlertTypes.success:
      return (
        <CheckCircleIcon
          className='!w-10 !h-10 font-light text-green-200'
          strokeWidth='1'
        />
      );
    case AlertTypes.warning:
      return (
        <ExclamationTriangleIcon
          className='!w-10 !h-10 text-yellow-300'
          strokeWidth='1'
        />
      );
    case AlertTypes.danger:
      return (
        <XCircleIcon className='!w-10 !h-10 text-red-300 ' strokeWidth='1' />
      );
    default:
      return (
        <LightBulbIcon className='!w-10 !h-10 text-[#223654]' strokeWidth='1' />
      );
  }
};

export default AlertIcon;
