import React, { cloneElement, ReactElement } from 'react';
import classNames from '../utils/classList.utils';
import Spinner from '../components/Spinner';

export enum ButtonTypes {
  button = 'button',
  reset = 'reset',
  submit = 'submit',
}

interface ButtonBase {
  label: string;
  primary?: boolean;
  type?: ButtonTypes;
  active?: boolean;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  compact?: boolean;
  loading?: boolean;
  icon?: ReactElement;
}

interface OnClickButton extends ButtonBase {
  href?: never;
  onClick: ((e?: React.MouseEvent<HTMLElement>) => void) | (() => void);
}

interface SubmitButton extends ButtonBase {
  type: ButtonTypes.submit;
  href?: never;
  onClick?: never;
}

interface LinkButton extends ButtonBase {
  href: string;
  onClick?: never;
}

type ButtonProps = LinkButton | OnClickButton | SubmitButton;

const Button = ({
  label,
  onClick,
  href,
  primary,
  type = ButtonTypes.button,
  disabled,
  compact,
  icon,
  className = '',
  loading,
}: ButtonProps) => {
  const classes = {
    shared:
      '!w-full sm:!w-auto font-bold border-blue-400 transition-all flex flex-row items-center gap-3 justify-center',
    primary:
      'bg-blue-400 text-white hover:border-blue-300 hover:bg-blue-300 disabled:cursor-not-allowed disabled:bg-blue-400/[0.8] shadow-lg',
    secondary:
      'bg-truewhite hover:bg-blue-100 text-blue-400 hover:bg-truewhite-200 disabled:cursor-not-allowed',
  };

  const iconWithProps =
    icon &&
    cloneElement(icon, {
      className: `${primary ? 'text-white' : 'text-blue-600'}`,
    });

  return (
    <>
      {href ? (
        <a
          className={classNames(
            classes.shared,
            primary ? classes.primary : classes.secondary,
            compact ? '!px-4 !py-2 text-sm !border' : '!px-6 !py-4 !border-2',
            'hover:no-underline !inline-block',
            className
          )}
          href={href}
        >
          <span className='flex flex-row items-center gap-3 justify-center'>
            {loading && <Spinner />}
            {iconWithProps}
            {label}
          </span>
        </a>
      ) : (
        <button
          type={type}
          className={classNames(
            classes.shared,
            primary ? classes.primary : classes.secondary,
            compact ? '!px-4 !py-2 text-sm !border' : '!px-6 !py-4 !border-2',
            className
          )}
          onClick={onClick}
          disabled={disabled}
        >
          {loading && <Spinner />}
          {iconWithProps}
          {label}
        </button>
      )}
    </>
  );
};

const isPromise = (x: unknown): x is Promise<unknown> =>
  typeof (x as Promise<unknown>)?.then === 'function';

export const PromiseButton = ({ onClick, ...props }: OnClickButton) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const onPromiseClick = (e?: React.MouseEvent<HTMLElement>) => {
    const output = onClick(e);
    if (isPromise(output)) {
      setIsLoading(true);
      output.finally(() => setIsLoading(false));
    }
  };

  return (
    <Button
      {...props}
      onClick={onPromiseClick}
      loading={isLoading}
      disabled={isLoading}
    />
  );
};

export default Button;
