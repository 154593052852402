import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from '../alerts/Alert';
import { AlertTypes } from '../alerts/types';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import Button, { ButtonTypes } from '../forms/Button';
import Form from '../forms/Form';
import RegisteredTextField from '../forms/registeredFields/RegisteredTextField';
import Columns from '../components/containers/Columns';
import { dataverseFetchWithNotification } from '../api/methods/http';
import {
  generateRequestAccessFormSchema,
  requestAccessFormDefaultValues,
} from '../utils/requestAccessForm.utils';
import { zodResolver } from '@hookform/resolvers/zod';
import i18n from '../i18n';
import { waitSeconds } from '../utils/authFormCommon.utils';

const RequestAccess = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(generateRequestAccessFormSchema(i18n.language)),
    defaultValues: requestAccessFormDefaultValues,
  });

  const [submitted, setSubmitted] = useState(false);

  const submitFn = async (data: any) => {
    const { code, lastName } = data;

    const response = await dataverseFetchWithNotification<{ demande: string }>(
      '/linking',
      {
        method: 'POST',
        body: JSON.stringify({ code, lastName }),
      }
    );

    await waitSeconds(2);
    if (response.demande) {
      window.location.href = `/wait?demandeId=${response.demande}`;
    }
    setSubmitted(true);
  };

  return (
    <ScreenContainer>
      <SectionContainer>
        <h1 className='heading1'>{t('requestAccess.heading')}</h1>
        {submitted ? (
          <>
            <Alert
              type={AlertTypes.success}
              header={t('requestAccess.confirmation.heading')}
              message={t('requestAccess.confirmation.text')}
            />
            <Button
              href='/portal'
              label={t('requestAccess.confirmation.return')}
            />
          </>
        ) : (
          <Form onSubmit={handleSubmit(submitFn)}>
            <p className='!mb-8'>{t('requestAccess.search.paragraph')}</p>
            <Columns numOfCols={3} className='gap-1'>
              <div className='col-span-2'>
                <RegisteredTextField
                  name={'code'}
                  register={register}
                  label={t('requestAccess.search.code')}
                  required
                  errorMessage={errors.code?.message}
                />
              </div>
              <div className='col-span-2'>
                <RegisteredTextField
                  name={'lastName'}
                  register={register}
                  label={t('requestAccess.search.name')}
                  required
                  errorMessage={errors.lastName?.message}
                />
              </div>
            </Columns>
            <Button
              type={ButtonTypes.submit}
              label={t('requestAccess.search.button')}
              primary
            />
          </Form>
        )}
      </SectionContainer>
    </ScreenContainer>
  );
};

export default RequestAccess;
