import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { GpaAPI, useApi } from '../api/APIContext';
import BackButton from '../components/BackButton';
import LinkList from '../components/LinkList';
import Pill from '../components/Pill';
import RequestDetails from '../components/RequestDetails';
import Accordion from '../components/containers/Accordion';
import Columns from '../components/containers/Columns';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import Button, { ButtonTypes, PromiseButton } from '../forms/Button';
import Form from '../forms/Form';
import RegisteredCheckbox from '../forms/registeredFields/RegisteredCheckbox';
import RegisteredCheckboxGroup from '../forms/registeredFields/RegisteredCheckboxGroup';
import RegisteredDateField from '../forms/registeredFields/RegisteredDateField';
import RegisteredRadioGroup from '../forms/registeredFields/RegisteredRadioGroup';
import RegisteredSelect from '../forms/registeredFields/RegisteredSelect';
import RegisteredTextArea from '../forms/registeredFields/RegisteredTextArea';
import RegisteredTextField from '../forms/registeredFields/RegisteredTextField';
import i18n from '../i18n';
import { getFormattedDateTime } from '../lib/dateformat';
import {
  getGametesOptions,
  getParentReproductiveMaterialUsedOptions,
  parentAuthSchema,
} from '../utils/parentAuthForm.utils';
import { SignatureStatus } from '../utils/types';
import {
  determineSignatureState,
  getDemandeParents,
  uploadPDFForm,
  waitSeconds,
} from '../utils/authFormCommon.utils';
import OtherParentSignatureSection from '../forms/OtherParentSignatureSection';
import { useActiveDemande } from '../forms/hooks/useActiveDemande';
import { useFetchedSelectOptions } from '../forms/hooks/useFetchedSelectOptions';
import CustomMarkdown from '../components/CustomMarkdown';
import SignatureStateSection from '../forms/SignatureStateSection';
import { eighteenYearsAgo, in15Years, today } from '../utils/date.utils';
import { Alert } from '../alerts/Alert';

const parentAuthFormTypesGetter = (api: GpaAPI) =>
  api.demandes.getParentAuthFormTypes();

const ParentAuth = () => {
  const { t } = useTranslation();
  const api = useApi();
  const demande = useActiveDemande();
  const getSelectOptions = useFetchedSelectOptions(parentAuthFormTypesGetter);
  const [signingStatus, setSigningStatus] = useState<SignatureStatus>();

  const { selfParent, otherParent } = getDemandeParents(demande);
  const [signatureState, setSignatureState] = useState(
    determineSignatureState(demande, signingStatus)
  );
  const {
    selfParentHasSigned,
    otherParentHasSigned,
    someoneHasSigned,
    signaturesRequiredCount,
  } = signatureState;

  const {
    handleSubmit,
    register,
    resetField,
    getValues,
    watch,
    formState: { errors, isSubmitting, isLoading },
    setValue,
    trigger,
  } = useForm({
    resolver: zodResolver(parentAuthSchema(i18n.language)),
    defaultValues: async () => api.demandes.getParentAuth(),
    shouldUnregister: true,
  });

  const forceGettingSignatureStatus = () => {
    return api.demandes.getParentAuthSigningStatus().then((signing) => {
      setSigningStatus(signing);
      return signing;
    });
  };
  useEffect(() => {
    forceGettingSignatureStatus().then();
  }, [api.demandes]);

  useEffect(() => {
    console.log('recalculating signature state');
    setSignatureState(determineSignatureState(demande, signingStatus));
  }, [demande, signingStatus]);
  const saveForm = () => api.demandes.saveParentAuth(getValues());

  const sign = async (data: any) => {
    if (demande) {
      //TODO fix this next line, it does not return the signature status.
      // const signedParentAuth =      await api.demandes.signParentAuth(getValues());

      await api.demandes.signParentAuth(getValues());
      const signedParentAuth = await forceGettingSignatureStatus();
      const signatureStateAfterSigning = determineSignatureState(
        demande,
        signedParentAuth
      );

      console.log({ signedParentAuth, signatureStateAfterSigning });

      if (signatureStateAfterSigning.isSigningComplete) {
        await waitSeconds(0.1);
        await uploadPDFForm(
          '.exportable-form',
          api,
          demande,
          t('parentAuth.title'),
          3
        );
        window.location.href = `/signed?demandeId=${demande.id}`;
      }
    }
  };

  const links: { label: string; href: string }[] = t('parentAuth.links.items', {
    returnObjects: true,
  });

  const surrogateMaterialUsed = watch('surrogateMaterialUsed');
  const reproductiveMaterialParent1 = watch('reproductiveMaterial.parent1');
  const reproductiveMaterialParent2 = watch('reproductiveMaterial.parent2');
  const donationMaterial = watch('reproductiveMaterial.donationMaterial');
  const gametesOptions = getGametesOptions(
    surrogateMaterialUsed === 'yes' ||
      reproductiveMaterialParent1 === 'ovocyte' ||
      reproductiveMaterialParent2 === 'ovocyte',
    reproductiveMaterialParent1 === 'sperm' ||
      reproductiveMaterialParent2 === 'sperm',
    t
  );

  const parentReproductiveMaterialUsedOptions =
    getParentReproductiveMaterialUsedOptions(
      t,
      watch().surrogateMaterialUsed === 'yes'
    );

  const hasChanges = watch('changes.hasChanges');
  const changes = watch('changes.changes');
  const changesIncludeOtherChanges = changes?.includes('other');
  const [parent1, parent2] = watch([
    'reproductiveMaterial.parent1',
    'reproductiveMaterial.parent2',
  ]);

  useEffect(() => {
    const getStatus = (hasChanges: string, changes: string[]) => {
      switch (true) {
        case hasChanges === 'no':
          return 'noChanges';
        case hasChanges === 'yes' &&
          changes?.includes('maritalStatus') &&
          changes?.includes('residence'):
          return 'hasMaritalAndResidenceChanges';
        case hasChanges === 'yes' && changes?.includes('maritalStatus'):
          return 'hasMaritalStatusChanges';
        case hasChanges === 'yes' && changes?.includes('residence'):
          return 'hasResidenceChanges';
        case hasChanges === 'yes':
          return 'undeterminedChanges';
        default:
          return '';
      }
    };
    const status = getStatus(hasChanges, changes);

    setValue('changes.status', status);

    trigger('changes');
  }, [hasChanges, changes, setValue]);

  useEffect(() => {
    const materials = [parent1, parent2];
    if (surrogateMaterialUsed === 'yes') {
      materials.push('ovocyte');
      if (parent1 === 'ovocyte') {
        setValue('reproductiveMaterial.parent1', 'none');
      }
      if (parent2 === 'ovocyte') {
        setValue('reproductiveMaterial.parent2', 'none');
      }
      if (donationMaterial === 'ovocyte') {
        setValue('reproductiveMaterial.donationMaterial', undefined);
      }
    }
    if (materials.includes('ovocyte') && materials.includes('sperm')) {
      setValue('reproductiveMaterial.needsDonationMaterial', 'no');
    } else {
      setValue('reproductiveMaterial.needsDonationMaterial', 'yes');
    }
    trigger('reproductiveMaterial');
  }, [parent1, parent2, setValue, donationMaterial, surrogateMaterialUsed]);

  return (
    <ScreenContainer>
      <Form onSubmit={handleSubmit(sign)}>
        <div className='sticky top-0 z-10 flex justify-between !px-4 !py-2 -!mx-4 bg-truewhite border-b border-gray-200 sm:-!mx-6 lg:-!mx-8 sm:!px-6 lg:!px-8'>
          <BackButton />
          {!someoneHasSigned && (
            <PromiseButton
              label={t('common.forms.save')}
              type={ButtonTypes.button}
              onClick={saveForm}
              primary
              compact
            />
          )}
        </div>
        <div className={'exportable-form'}>
          <fieldset disabled={isLoading || someoneHasSigned}>
            <SignatureStateSection signatureState={signatureState} />

            <SectionContainer>
              <h1 className='heading1'>{t('parentAuth.title')}</h1>
              <RequestDetails requestData={demande} />
            </SectionContainer>
            <SectionContainer>
              <LinkList title={t('parentAuth.links.title')} links={links} />
            </SectionContainer>
            <SectionContainer>
              <h2 id='instructions' className='heading2'>
                {t('parentAuth.instructions.heading')}
              </h2>
              <CustomMarkdown
                content={t('parentAuth.instructions.paragraph')}
                className='!mb-10'
              />
              <Accordion
                title={t('parentAuth.instructions.accordion1.heading')}
              >
                <CustomMarkdown
                  content={t('parentAuth.instructions.accordion1.paragraph')}
                />
                <ReactMarkdown className='!mt-5'>
                  {t('parentAuth.instructions.accordion1.note')}
                </ReactMarkdown>
              </Accordion>
              <Accordion
                title={t('parentAuth.instructions.accordion2.heading')}
              >
                <ReactMarkdown>
                  {t('parentAuth.instructions.accordion2.paragraph')}
                </ReactMarkdown>
              </Accordion>
              <ReactMarkdown className='!mt-5 whitespace-pre-wrap'>
                {t('parentAuth.instructions.accordion2.note')}
              </ReactMarkdown>
            </SectionContainer>
            <SectionContainer>
              <h2 id='regulations' className='heading2'>
                {t('parentAuth.regulations.heading')}
              </h2>
              <ReactMarkdown className='whitespace-pre-wrap'>
                {t('parentAuth.regulations.paragraph')}
              </ReactMarkdown>
              <ReactMarkdown>{t('parentAuth.regulations.list')}</ReactMarkdown>
              <ReactMarkdown className='!my-5'>
                {t('parentAuth.regulations.disclaimer')}
              </ReactMarkdown>
            </SectionContainer>
            <SectionContainer>
              <h2 className='heading2' id='parentalChanges'>
                {t('parentAuth.parentalChanges.heading')}
              </h2>
              <p>{t('parentAuth.parentalChanges.paragraph')}</p>
            </SectionContainer>
            <SectionContainer>
              <h3 className='heading3'>
                {t('parentAuth.changeForm.parentChange.heading')}
              </h3>

              <RegisteredRadioGroup
                required
                name={'changes.hasChanges'}
                register={register}
                label={t('parentAuth.changeForm.parentChange.selectHeading')}
                options={[
                  { value: 'yes', label: t('common.yes') },
                  { value: 'no', label: t('common.no') },
                ]}
                errorMessage={errors.changes?.hasChanges?.message}
              />
              {watch('changes.hasChanges') === 'yes' && (
                <>
                  <RegisteredCheckboxGroup
                    label={t(
                      'parentAuth.changeForm.parentChange.checkbox.heading'
                    )}
                    required={true}
                    errorMessage={errors.changes?.changes?.message}
                    name='changes.changes'
                    register={register}
                    options={[
                      {
                        label: t(
                          'parentAuth.changeForm.parentChange.checkbox.maritalStatus.heading'
                        ),
                        name: 'maritalStatus',
                        description: t(
                          'parentAuth.changeForm.parentChange.checkbox.maritalStatus.sub'
                        ),
                      },
                      {
                        label: t(
                          'parentAuth.changeForm.parentChange.checkbox.residence.heading'
                        ),
                        name: 'residence',
                        description: t(
                          'parentAuth.changeForm.parentChange.checkbox.residence.sub'
                        ),
                      },
                      {
                        label: t(
                          'parentAuth.changeForm.parentChange.checkbox.other'
                        ),
                        name: 'other',
                      },
                    ]}
                  />
                  {watch('changes.changes')?.includes('other') && (
                    <Alert
                      type={'danger'}
                      message={t(
                        'parentAuth.changeForm.parentChange.checkbox.paragraph'
                      )}
                      button={
                        <Button
                          label={t('changeRequest.title')}
                          className={'mt-4'}
                          primary
                          compact
                          type={ButtonTypes.button}
                          onClick={async (e) => {
                            e?.preventDefault();
                            await saveForm();
                            window.location.href = `/changeRequest?demandeId=${demande?.id}`;
                          }}
                        ></Button>
                      }
                    />
                  )}

                  {watch('changes.changes')?.includes('maritalStatus') ||
                    (watch('changes.changes')?.includes('residence') && (
                      <h3 className='heading3'>
                        {t('parentAuth.changeForm.parentChange.docs.heading')}
                      </h3>
                    ))}
                  {watch('changes.changes')?.includes('maritalStatus') && (
                    <>
                      <RegisteredSelect
                        required
                        name='changes.changeAttachedDoc'
                        register={register}
                        label={t(
                          'parentAuth.changeForm.parentChange.docs.marriageStatusSelect.title'
                        )}
                        errorMessage={
                          errors.changes?.changeAttachedDoc?.message
                        }
                        options={[
                          {
                            label: t(
                              'parentAuth.changeForm.parentChange.docs.marriageStatusSelect.options.marriage'
                            ),
                            value: 'marriage',
                          },
                          {
                            label: t(
                              'parentAuth.changeForm.parentChange.docs.marriageStatusSelect.options.civilUnion'
                            ),
                            value: 'civilUnion',
                          },
                        ]}
                        placeholder={t('common.forms.selectPlaceholder')}
                      />
                    </>
                  )}
                  {watch('changes.changes')?.includes('residence') && (
                    <>
                      <RegisteredSelect
                        required
                        name={'changes.residenceChangeDoc'}
                        register={register}
                        errorMessage={
                          errors.changes?.residenceChangeDoc?.message
                        }
                        label={t(
                          'parentAuth.changeForm.parentChange.docs.residenceSelect.title'
                        )}
                        options={getSelectOptions(
                          'preuvedecitoyenneteouresidenceperm'
                        )}
                        placeholder={t('common.forms.selectPlaceholder')}
                      />
                      <RegisteredTextArea
                        required
                        name={'changes.parentChangeExplanation'}
                        errorMessage={
                          errors.changes?.parentChangeExplanation?.message
                        }
                        register={register}
                        title={t(
                          'parentAuth.changeForm.parentChange.docs.residenceSelect.explanation.title'
                        )}
                      />
                    </>
                  )}
                </>
              )}
              {watch('changes.changes')?.includes('other') ? (
                <></>
              ) : (
                <>
                  <h2 className='heading2' id='surrogate'>
                    {t('parentAuth.changeForm.surrogate.heading')}
                  </h2>
                  <h3 className='heading3'>
                    {t('parentAuth.changeForm.surrogate.subheading')}
                  </h3>
                  <Columns numOfCols={2}>
                    <RegisteredTextField
                      errorMessage={errors.surrogate?.lastName?.message}
                      required
                      name={'surrogate.lastName'}
                      register={register}
                      label={t('parentAuth.changeForm.surrogate.id.familyName')}
                    />
                    <RegisteredTextField
                      required
                      errorMessage={errors.surrogate?.firstName?.message}
                      name={'surrogate.firstName'}
                      register={register}
                      label={t('parentAuth.changeForm.surrogate.id.firstName')}
                    />
                  </Columns>
                  <Columns numOfCols={2}>
                    <RegisteredDateField
                      required
                      max={eighteenYearsAgo()}
                      errorMessage={errors.surrogate?.dob?.message}
                      name={'surrogate.dob'}
                      register={register}
                      label={t('parentAuth.changeForm.surrogate.id.birthday')}
                    />
                  </Columns>
                  <h3 className='heading3'>
                    {t('parentAuth.changeForm.surrogate.contact.heading')}
                  </h3>
                  <Columns numOfCols={3}>
                    <RegisteredTextField
                      required
                      errorMessage={errors.surrogate?.address?.message}
                      name={'surrogate.address'}
                      register={register}
                      label={t(
                        'parentAuth.changeForm.surrogate.contact.address'
                      )}
                    />
                    <RegisteredTextField
                      required
                      name={'surrogate.city'}
                      register={register}
                      label={t('parentAuth.changeForm.surrogate.contact.city')}
                      errorMessage={errors.surrogate?.city?.message}
                    />
                    <RegisteredSelect
                      required
                      name={'surrogate.state'}
                      register={register}
                      label={t('parentAuth.changeForm.surrogate.contact.state')}
                      options={getSelectOptions('etats')}
                      errorMessage={errors.surrogate?.state?.message}
                      placeholder={t('common.forms.selectPlaceholder')}
                    />
                  </Columns>
                  <Columns numOfCols={3}>
                    <RegisteredTextField
                      required
                      name={'surrogate.postalCode'}
                      errorMessage={errors.surrogate?.postalCode?.message}
                      register={register}
                      label={t(
                        'parentAuth.changeForm.surrogate.contact.postalCode'
                      )}
                    />
                    <RegisteredTextField
                      required
                      name={'surrogate.telephone'}
                      register={register}
                      label={t('parentAuth.changeForm.surrogate.contact.phone')}
                      errorMessage={errors.surrogate?.telephone?.message}
                    />
                    <RegisteredTextField
                      name={'surrogate.email'}
                      register={register}
                      errorMessage={errors.surrogate?.email?.message}
                      label={t('parentAuth.changeForm.surrogate.contact.email')}
                    />
                  </Columns>

                  <h3 className='heading3'>
                    {t('parentAuth.changeForm.surrogate.attachDocs.heading')}
                  </h3>
                  <Columns numOfCols={2}>
                    <div>
                      <RegisteredSelect
                        required
                        name={'documents.idDocType'}
                        register={register}
                        label={t(
                          'parentAuth.changeForm.surrogate.attachDocs.idDocument.type'
                        )}
                        options={getSelectOptions('preuvevalidationpi')}
                        errorMessage={errors.documents?.idDocType?.message}
                        placeholder={t('common.forms.selectPlaceholder')}
                      />
                      <RegisteredDateField
                        max={in15Years()}
                        min={today()}
                        name={'documents.idDocExpiration'}
                        register={register}
                        label={t(
                          'parentAuth.changeForm.surrogate.attachDocs.idDocument.expiration'
                        )}
                        errorMessage={
                          errors.documents?.idDocExpiration?.message
                        }
                      />
                      <p className='-!mt-8'>
                        {t(
                          'parentAuth.changeForm.surrogate.attachDocs.idDocument.miniText'
                        )}
                      </p>
                    </div>
                    <div>
                      <RegisteredSelect
                        required
                        name={'documents.addressDocType'}
                        register={register}
                        label={t(
                          'parentAuth.changeForm.surrogate.attachDocs.addressDoc.type'
                        )}
                        options={getSelectOptions('preuvedomicile')}
                        placeholder={t('common.forms.selectPlaceholder')}
                        errorMessage={errors.documents?.addressDocType?.message}
                      />
                      <RegisteredDateField
                        max={in15Years()}
                        min={today()}
                        name={'documents.addressDocExpiration'}
                        register={register}
                        label={t(
                          'parentAuth.changeForm.surrogate.attachDocs.addressDoc.expiration'
                        )}
                        errorMessage={
                          errors.documents?.addressDocExpiration?.message
                        }
                      />
                      <p className='-!mt-8'>
                        {t(
                          'parentAuth.changeForm.surrogate.attachDocs.addressDoc.miniText'
                        )}
                      </p>
                    </div>
                  </Columns>

                  <h3 className='heading3'>
                    {t(
                      'parentAuth.changeForm.surrogate.confirmAltruism.heading'
                    )}
                  </h3>
                  <RegisteredCheckbox
                    required
                    name={'altruism'}
                    register={register}
                    errorMessage={errors.altruism?.message}
                    label={
                      demande?.isCouple
                        ? t(
                            'parentAuth.changeForm.surrogate.confirmAltruism.textPlural'
                          )
                        : t(
                            'parentAuth.changeForm.surrogate.confirmAltruism.textSingular'
                          )
                    }
                  />

                  <h3 className='heading3'>
                    {t('parentAuth.changeForm.surrogate.general.heading')}
                  </h3>
                  <Columns numOfCols={2}>
                    <RegisteredTextField
                      required
                      name={'ethnicOrigin'}
                      register={register}
                      label={t(
                        'parentAuth.changeForm.surrogate.general.ethnicOrigin'
                      )}
                      errorMessage={errors.ethnicOrigin?.message}
                    />
                    <RegisteredTextField
                      required
                      name={'education'}
                      register={register}
                      label={t(
                        'parentAuth.changeForm.surrogate.general.education'
                      )}
                      errorMessage={errors.education?.message}
                    />
                  </Columns>
                  <RegisteredTextArea
                    required
                    name={'diplomas'}
                    register={register}
                    title={t(
                      'parentAuth.changeForm.surrogate.general.diplomas'
                    )}
                    errorMessage={errors.diplomas?.message}
                  />
                  <RegisteredTextField
                    required
                    name={'profession'}
                    register={register}
                    label={t(
                      'parentAuth.changeForm.surrogate.general.profession'
                    )}
                    errorMessage={errors.profession?.message}
                  />
                  <RegisteredRadioGroup
                    required
                    name={'surrogateRelationship.maritalStatus'}
                    register={register}
                    errorMessage={
                      errors.surrogateRelationship?.maritalStatus?.message
                    }
                    options={[
                      {
                        label: t(
                          'parentAuth.changeForm.surrogate.maritalStatus.single'
                        ),
                        value: 'single',
                      },
                      {
                        label: t(
                          'parentAuth.changeForm.surrogate.maritalStatus.civilUnion'
                        ),
                        value: 'civilUnion',
                      },
                      {
                        label: t(
                          'parentAuth.changeForm.surrogate.maritalStatus.commonLaw'
                        ),
                        value: 'commonLaw',
                      },
                      {
                        label: t(
                          'parentAuth.changeForm.surrogate.maritalStatus.married'
                        ),
                        value: 'married',
                      },
                    ]}
                    label={t(
                      'parentAuth.changeForm.surrogate.maritalStatus.heading'
                    )}
                  />

                  <h3 className='heading3'>
                    {t(
                      'parentAuth.changeForm.surrogate.physicalCharacteristics.heading'
                    )}
                  </h3>
                  <Columns numOfCols={2}>
                    <RegisteredTextField
                      required
                      name={'height'}
                      register={register}
                      label={t(
                        'parentAuth.changeForm.surrogate.physicalCharacteristics.size'
                      )}
                      errorMessage={errors.height?.message}
                    />
                    <RegisteredTextField
                      required
                      name={'eyeColour'}
                      register={register}
                      label={t(
                        'parentAuth.changeForm.surrogate.physicalCharacteristics.eyeColour'
                      )}
                      errorMessage={errors.eyeColour?.message}
                    />
                  </Columns>
                  <Columns numOfCols={2}>
                    <RegisteredTextField
                      required
                      name={'skinColour'}
                      register={register}
                      label={t(
                        'parentAuth.changeForm.surrogate.physicalCharacteristics.skinColour'
                      )}
                      errorMessage={errors.skinColour?.message}
                    />
                    <RegisteredTextField
                      required
                      name={'hairColour'}
                      register={register}
                      label={t(
                        'parentAuth.changeForm.surrogate.physicalCharacteristics.hair'
                      )}
                      errorMessage={errors.hairColour?.message}
                    />
                  </Columns>

                  <h3 className='heading3'>
                    {t(
                      'parentAuth.changeForm.surrogate.otherInformation.heading'
                    )}
                  </h3>
                  <RegisteredTextArea
                    required
                    name={'personalityTraits'}
                    register={register}
                    title={t(
                      'parentAuth.changeForm.surrogate.otherInformation.personalityTraits'
                    )}
                    errorMessage={errors.personalityTraits?.message}
                  />
                  {errors.personalityTraits?.message}
                  <RegisteredTextArea
                    name={'motivations'}
                    register={register}
                    title={t(
                      'parentAuth.changeForm.surrogate.otherInformation.motivations'
                    )}
                    errorMessage={errors.motivations?.message}
                  />

                  {watch().surrogateRelationship?.maritalStatus !== '' &&
                    watch().surrogateRelationship?.maritalStatus !==
                      'single' && (
                      <>
                        <h2 className='heading2' id='surrogateSpouse'>
                          {t('parentAuth.changeForm.spouseOfSurrogate.heading')}
                        </h2>
                        <p>
                          {t(
                            'parentAuth.changeForm.spouseOfSurrogate.paragraph'
                          )}
                        </p>
                        <h3 className='heading3'>
                          {t(
                            'parentAuth.changeForm.spouseOfSurrogate.id.heading'
                          )}
                        </h3>
                        <Columns numOfCols={2}>
                          <RegisteredTextField
                            name={'surrogateRelationship.spouseFamilyName'}
                            register={register}
                            label={t(
                              'parentAuth.changeForm.spouseOfSurrogate.id.familyName'
                            )}
                            errorMessage={
                              errors.surrogateRelationship?.spouseFamilyName
                                ?.message
                            }
                          />
                          <RegisteredTextField
                            name={'surrogateRelationship.spouseName'}
                            errorMessage={
                              errors.surrogateRelationship?.spouseName?.message
                            }
                            register={register}
                            label={t(
                              'parentAuth.changeForm.spouseOfSurrogate.id.firstName'
                            )}
                          />
                        </Columns>
                      </>
                    )}
                  <h2 className='heading2' id='reproductiveMaterial'>
                    {t(
                      'parentAuth.changeForm.reproductiveMaterialUsed.heading'
                    )}
                  </h2>
                  <h2 className='heading3'>
                    {t(
                      'parentAuth.changeForm.reproductiveMaterialUsed.surrogate'
                    )}
                  </h2>

                  <RegisteredRadioGroup
                    required
                    name={'surrogateMaterialUsed'}
                    register={register}
                    options={[
                      { value: 'yes', label: t('common.yes') },
                      { value: 'no', label: t('common.no') },
                    ]}
                    errorMessage={errors.surrogateMaterialUsed?.message}
                    label={t(
                      'parentAuth.changeForm.reproductiveMaterialUsed.geneticLink.radio'
                    )}
                  />
                  {watch().surrogateMaterialUsed === 'yes' && (
                    <>
                      <h4 className='heading4'>
                        {t(
                          'parentAuth.changeForm.reproductiveMaterialUsed.geneticLink.heading'
                        )}
                      </h4>
                      <p className='italic'>
                        {t(
                          'parentAuth.changeForm.reproductiveMaterialUsed.geneticLink.italics'
                        )}
                      </p>
                    </>
                  )}
                  <h3 className='heading3'>
                    {t(
                      'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.heading'
                    )}
                  </h3>
                  <h4 className='heading4'>
                    {t(
                      'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.subheading'
                    )}
                  </h4>
                  {/*{!true && (*/}
                  {/*  <ReactMarkdown className='!my-5'>*/}
                  {/*    {t(*/}
                  {/*      'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.reminder'*/}
                  {/*    )}*/}
                  {/*  </ReactMarkdown>*/}
                  {/*)}*/}
                  <Columns numOfCols={2}>
                    <RegisteredRadioGroup
                      required
                      errorMessage={
                        errors.reproductiveMaterial?.parent1?.message
                      }
                      name={'reproductiveMaterial.parent1'}
                      register={register}
                      options={parentReproductiveMaterialUsedOptions.filter(
                        (e) =>
                          watch().reproductiveMaterial?.parent2 === 'ovocyte'
                            ? e.value !== 'ovocyte'
                            : true
                      )}
                      label={t(
                        'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.parent.heading',
                        {
                          parentNumber: '1',
                          parentName: `(${demande?.parents?.[0]?.firstName} ${demande?.parents?.[0]?.lastName})`,
                        }
                      )}
                    />
                    <div>
                      {demande?.isCouple && (
                        <RegisteredRadioGroup
                          required
                          name={'reproductiveMaterial.parent2'}
                          errorMessage={
                            errors.reproductiveMaterial?.parent2?.message
                          }
                          register={register}
                          options={parentReproductiveMaterialUsedOptions.filter(
                            (e) =>
                              watch().reproductiveMaterial?.parent1 ===
                              'ovocyte'
                                ? e.value !== 'ovocyte'
                                : true
                          )}
                          label={t(
                            'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.parent.heading',
                            {
                              parentNumber: '2',
                              parentName: `(${demande?.parents?.[1]?.firstName} ${demande?.parents?.[1]?.lastName})`,
                            }
                          )}
                        />
                      )}
                    </div>
                  </Columns>

                  {gametesOptions.length > 0 && (
                    <>
                      <h3 className='heading3'>
                        {t(
                          'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.donation.heading'
                        )}
                      </h3>
                      <RegisteredRadioGroup
                        required
                        errorMessage={
                          errors.reproductiveMaterial?.donationMaterial?.message
                        }
                        name={'reproductiveMaterial.donationMaterial'}
                        register={register}
                        options={gametesOptions}
                        label={t(
                          'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.donation.subheading'
                        )}
                      />
                    </>
                  )}

                  <h3 className='heading3'>
                    {t(
                      'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.document.heading'
                    )}
                  </h3>
                  <Columns numOfCols={2}>
                    {/*<RegisteredSelect*/}
                    {/*  name={'reproductiveMaterialDocType'}*/}
                    {/*  errorMessage={errors.reproductiveMaterialDocType?.message}*/}
                    {/*  register={register}*/}
                    {/*  label={t(*/}
                    {/*    'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.document.select'*/}
                    {/*  )}*/}
                    {/*  options={getSelectOptions('centredeprocreationassistelettre')}*/}
                    {/*  placeholder={t('common.forms.selectPlaceholder')}*/}
                    {/*/>*/}
                    <RegisteredDateField
                      max={today()}
                      name={'reproductiveMaterialDocExpiration'}
                      errorMessage={
                        errors.reproductiveMaterialDocExpiration?.message
                      }
                      register={register}
                      label={t(
                        'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.document.date'
                      )}
                    />
                  </Columns>

                  <h2 className='heading2' id='surrogacyAgreement'>
                    {t('parentAuth.changeForm.surrogacyAgreement.heading')}
                  </h2>
                  <h3 className='!my-6 heading3'>
                    {t('parentAuth.changeForm.surrogacyAgreement.subheading')}
                  </h3>
                  <Columns numOfCols={2}>
                    <RegisteredDateField
                      max={today()}
                      required
                      name={'agreement.agreementDate'}
                      errorMessage={errors.agreement?.agreementDate?.message}
                      register={register}
                      label={t('parentAuth.changeForm.surrogacyAgreement.date')}
                    />
                  </Columns>

                  <h3 className='heading3'>
                    {t(
                      'parentAuth.changeForm.surrogacyAgreement.draftingLanguage'
                    )}
                  </h3>
                  <RegisteredRadioGroup
                    required
                    name={'agreement.language.isInFrench'}
                    errorMessage={
                      errors.agreement?.language?.isInFrench?.message
                    }
                    register={register}
                    options={[
                      { value: 'yes', label: t('common.yes') },
                      { value: 'no', label: t('common.no') },
                    ]}
                    label={t(
                      'parentAuth.changeForm.surrogacyAgreement.languageRadio'
                    )}
                  />
                  {watch().agreement?.language?.isInFrench === 'no' && (
                    <RegisteredTextField
                      required
                      name='agreement.language.agreementLanguage'
                      errorMessage={
                        errors.agreement?.language?.agreementLanguage?.message
                      }
                      register={register}
                      label={t(
                        'parentAuth.changeForm.surrogacyAgreement.ifNotFrench'
                      )}
                    />
                  )}

                  <h3 className='heading3'>
                    {t(
                      'parentAuth.changeForm.surrogacyAgreement.reimbursement.heading'
                    )}
                  </h3>
                  <RegisteredRadioGroup
                    required
                    name={'agreement.reimbursement.isReimbursed'}
                    errorMessage={
                      errors.agreement?.reimbursement?.isReimbursed?.message
                    }
                    register={register}
                    options={[
                      { value: 'yes', label: t('common.yes') },
                      { value: 'no', label: t('common.no') },
                    ]}
                    label={t(
                      'parentAuth.changeForm.surrogacyAgreement.reimbursement.radio'
                    )}
                  />
                  {watch().agreement?.reimbursement.isReimbursed === 'no' && (
                    <>
                      <p>
                        {t(
                          'parentAuth.changeForm.surrogacyAgreement.reimbursement.paragraph'
                        )}
                      </p>
                      <h3 className='heading3'>
                        {t('parentAuth.changeForm.attachDoc1.heading')}
                      </h3>
                      <Columns numOfCols={2}>
                        <RegisteredDateField
                          required
                          name={'agreement.reimbursement.signDate'}
                          errorMessage={
                            errors.agreement?.reimbursement?.signDate?.message
                          }
                          register={register}
                          label={t('parentAuth.changeForm.attachDoc1.date')}
                        />
                      </Columns>
                    </>
                  )}

                  <h2 className='heading2' id='signedDeclaration'>
                    {t('parentAuth.changeForm.attachDoc2.heading')}
                  </h2>
                  <h3 className='!my-6 heading3'>
                    {t('parentAuth.changeForm.attachDoc2.subheading')}
                  </h3>
                  <Columns numOfCols={2}>
                    <RegisteredDateField
                      required
                      name={'agreement.authorizationSignDate'}
                      errorMessage={
                        errors.agreement?.authorizationSignDate?.message
                      }
                      register={register}
                      label={t('parentAuth.changeForm.attachDoc2.date')}
                    />
                  </Columns>
                </>
              )}
            </SectionContainer>
          </fieldset>
          {watch('changes.changes')?.includes('other') ? (
            <></>
          ) : (
            <SectionContainer>
              <h3 className='!mt-20 heading3'>
                {t('changeRequest.signatureForm')}
              </h3>

              <Columns numOfCols={2}>
                <div className='flex flex-col'>
                  <h4 className={'heading4 !mb-2 space-x-4'}>
                    <span>
                      {selfParent?.firstName} {selfParent?.lastName}
                    </span>
                    {selfParentHasSigned ? (
                      <Pill
                        type={'success'}
                        text={t('common.signature.signedLabel')}
                      />
                    ) : (
                      <Pill
                        type={'warning'}
                        text={t('common.signature.toSignLabel')}
                      />
                    )}
                  </h4>

                  {!selfParentHasSigned ? (
                    <>
                      <RegisteredCheckbox
                        label={t('common.signature.clickToSign')}
                        name='signConsent'
                        register={register}
                      />
                      <Button
                        type={ButtonTypes.submit}
                        disabled={isSubmitting || !watch('signConsent')}
                        loading={isSubmitting}
                        primary
                        label={t('changeRequest.button')}
                        className='inline mr-auto'
                      />
                    </>
                  ) : (
                    <>
                      <p className={'!mb-8'}>
                        {t('common.signature.signed', {
                          name: `${selfParent?.firstName} ${selfParent?.lastName}`,
                          time: getFormattedDateTime(
                            selfParentHasSigned,
                            i18n.language
                          ),
                        })}
                      </p>
                    </>
                  )}
                </div>

                <OtherParentSignatureSection
                  otherParent={otherParent}
                  otherParentSignatureDate={otherParentHasSigned}
                />
              </Columns>
            </SectionContainer>
          )}
        </div>
      </Form>
    </ScreenContainer>
  );
};

export default ParentAuth;
