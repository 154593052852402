import { RadioButtonProps } from '../forms/RadioGroup/types';
import classNames from '../utils/classList.utils';

export default function RadioButton({
  fullWidth,
  label,
  radioRef,
  hasError,
  ...props
}: RadioButtonProps) {
  const id = `${props.name}-${props.value}`;

  return (
    <label
      className={classNames(
        'flex items-start !py-2',
        fullWidth ? '!w-full' : '!w-auto'
      )}
    >
      <input
        {...props}
        ref={radioRef}
        id={id}
        type='radio'
        className={classNames(
          '!w-5 !h-5 border-2 !mr-2 !mt-1 cursor-pointer focus:ring-2 focus:ring-blue-200 text-blue-300 bg-truewhite',
          hasError ? 'border-red-300' : 'border-blue-600'
        )}
      />
      {label}
    </label>
  );
}
