import { dataverseFetch, dataverseFetchWithNotification } from './http';

export interface InfoGPAFormTypes {
  requesterTypes: ChoiceList[];
  languages: ChoiceList[];
  infoType: ChoiceList[];
}

export const getFormTypes = async () =>
  dataverseFetch<InfoGPAFormTypes>('/infogpa/values');

export const submitInfoGPA = async (data: InfoGPACreation) => {
  return dataverseFetchWithNotification<{ infoGPAId: string }>('/infogpa', {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export interface InfoGPACreation {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  requesterType: number;
  infoType: number;
  message: string;
  consent: boolean;
}
